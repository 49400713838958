import update from 'immutability-helper';
import { ADD_ADDRESSES_FROM_JUKEBOX } from '../containers/bootstrap/Analytics/AnalyticsActions';
import {
  SAVE_UNFINISHED_DROPOFF_QUERY,
  SAVE_UNFINISHED_PICKUP_QUERY
} from '../containers/heroes/HomepageHero/HomepageHeroActions';
import { POPULATE_ADDRESS } from '../containers/bookingStep0/RoutePlanner/RoutePlannerActions';
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  pickup: null,
  dropoff: null
};

// Unfinished address queries
function unfinishedAddressQueries(state = initialState, action) {
  switch (action.type) {
    case ADD_ADDRESSES_FROM_JUKEBOX:
      return update(state, {$merge: action.payload});
    case SAVE_UNFINISHED_PICKUP_QUERY:
      return update(state, {
        pickup: {$set: action.payload}
      });
    case SAVE_UNFINISHED_DROPOFF_QUERY:
      return update(state, {
        dropoff: {$set: action.payload}
      });
    case POPULATE_ADDRESS:
      return update(state, {
        pickup: {$set: null},
        dropoff: {$set: null}
      });
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default unfinishedAddressQueries;
