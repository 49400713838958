import _ from "lodash";
import update from "immutability-helper";
import { SET_VAN_SIZE } from "../containers/bookingStep1/PricesList/PricesListActions";
import { SET_JOB_DESCRIPTION } from "../containers/bookingStep2/JobDescription/JobDescriptionActions";
import { SET_ASSISTANCE_LEVEL } from "../containers/bookingStep2/Customise/CustomiseActions";
import { SET_NUMBER_OF_STAIRS } from "../containers/bookingStep2/Customise/CustomiseActions";
import { SET_EXTRA_TIME_HOURS } from "../containers/bookingStep2/Customise/CustomiseActions";
import { TURN_RIDE_ALONG_ON } from "../containers/bookingStep2/Customise/CustomiseActions";
import { TURN_RIDE_ALONG_OFF } from "../containers/bookingStep2/Customise/CustomiseActions";
import { SET_PICKUP_DATE_AND_TIME } from "../containers/bookingStep3/DatePickerAndCollection/DatePickerAndCollectionActions";
import { RESET_BOOKING } from "../containers/bookingStep5/BookingSuccess/BookingSuccessActions";
import { SUBMIT_USER_INFO } from "../containers/heroes/RemindMeLater/RemindMeLaterActions";
import { SET_ARRIVAL_FROM_TIMETABLE } from "../containers/reserve/Timetable/TimetableActions";
import { PARTIAL_SIGNUP_FROM_SERVICE_PAGE } from "../containers/heroes/ServiceHero/ServiceHeroActions";
import { SET_JOB_NAME_FROM_HOMEPAGE } from "../containers/heroes/HomepageHero/HomepageHeroActions";
import {
  ON_INVENTORY_ADD,
  ON_INVENTORY_REMOVE,
} from "../containers/bookingStep6/InventoryForm/InventoryFormActions";

import {
  SET_COLLECTION_RANGE,
  SET_RIDE_ALONG_PASSENGERS,
} from "../actions/GeneralBookingActions";
import { POPULATE_GENERAL_BOOKING_INFO_FROM_EASY_BOOKING } from "../containers/easyBooking/EasyBooking/EasyBookingActions";

const initialState = {
  vanSizeIndex: null,
  helpLiftingIndex: 1,
  rideAlong: 0,
  noOfStairs: 0,
  extraTime: 0,
  name: null,
  jobDescription: null,
  arrival: null, // DatePickerAndCollection / pickup date and time

  // Collection range:
  collectionRange: 10,
  collectionStart: 8,
  collectionEnd: 18,
  inventory: [], // start working here Zain Abbas
};

function bookingGeneral(state = initialState, action) {
  switch (action.type) {
    case POPULATE_GENERAL_BOOKING_INFO_FROM_EASY_BOOKING:
      return update(state, {
        vanSizeIndex: { $set: action.payload.vanSizeIndex },
        helpLiftingIndex: { $set: action.payload.helpLiftingIndex },
        rideAlong: { $set: action.payload.rideAlong },
        extraTime: { $set: action.payload.extraTime },
        name: { $set: action.payload.name },
        jobDescription: { $set: action.payload.jobDescription },
        arrival: { $set: action.payload.arrival },
        collectionRange: { $set: action.payload.collectionRange },
        collectionStart: { $set: action.payload.collectionStart },
        collectionEnd: { $set: action.payload.collectionEnd },
      });
    case SET_COLLECTION_RANGE:
      return update(state, {
        collectionRange: { $set: action.payload.collectionRange },
        collectionStart: { $set: action.payload.collectionStart },
        collectionEnd: { $set: action.payload.collectionEnd },
      });
    case SET_JOB_NAME_FROM_HOMEPAGE:
      return update(state, {
        name: { $set: action.payload.jobName },
      });
    case PARTIAL_SIGNUP_FROM_SERVICE_PAGE:
      return update(state, {
        name: { $set: action.payload.jobName },
      });
    case SET_ARRIVAL_FROM_TIMETABLE:
      return update(state, {
        arrival: { $set: action.payload.dateAndTime },
      });
    case SUBMIT_USER_INFO:
      return update(state, {
        arrival: { $set: action.payload.pickup },
      });
    case SET_VAN_SIZE:
      return update(state, {
        vanSizeIndex: { $set: action.payload.vanSizeIndex },
      });
    case SET_JOB_DESCRIPTION:
      return update(state, {
        jobDescription: { $set: action.payload.userInput },
      });
    case SET_ASSISTANCE_LEVEL:
      return update(state, {
        helpLiftingIndex: { $set: action.payload.index },
      });
    case SET_NUMBER_OF_STAIRS:
      return update(state, {
        noOfStairs: { $set: action.payload.amount },
      });
    case SET_RIDE_ALONG_PASSENGERS:
      return update(state, {
        rideAlong: { $set: action.payload },
      });
    case TURN_RIDE_ALONG_ON:
      return update(state, {
        rideAlong: { $set: true },
      });
    case TURN_RIDE_ALONG_OFF:
      return update(state, {
        rideAlong: { $set: false },
      });
    case SET_EXTRA_TIME_HOURS:
      return update(state, {
        extraTime: { $set: action.payload.amount },
      });
    case SET_PICKUP_DATE_AND_TIME:
      return update(state, {
        arrival: { $set: action.payload.pickupDateAndTime },
      });

    case ON_INVENTORY_ADD: {
      const inventoryClone = _.cloneDeep(state.inventory);
      const indexOfExistingInventory = _.findIndex(inventoryClone, {
        title: action.payload.title,
      });

      if (indexOfExistingInventory === -1) {
        // does not exist
        inventoryClone.push(action.payload);
      } else {
        // yes it exist
        inventoryClone[indexOfExistingInventory].quantity =
          action.payload.quantity;
      }
      return update(state, {
        inventory: { $set: inventoryClone },
      });
    }

    case ON_INVENTORY_REMOVE: {
      const inventoryClone = _.cloneDeep(state.inventory);
      inventoryClone.splice(action.index, 1);

      return update(state, {
        inventory: { $set: inventoryClone },
      });
    }

    case RESET_BOOKING:
      return initialState; // also removes one use token
    default:
      return state;
  }
}

export default bookingGeneral;
