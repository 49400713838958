export const SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME = 'SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME';
export const RESET_YOUR_QUOTE_IS_VALID_FOR = 'RESET_YOUR_QUOTE_IS_VALID_FOR';

export function saveYourQuoteIsValidForTime(seconds) {
  return {
    type: SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME,
    payload: seconds
  }
}

export function resetYourQuoteIsValidFor() {
  return {
    type: RESET_YOUR_QUOTE_IS_VALID_FOR
  }
}
