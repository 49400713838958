export const SET_ARRIVAL_FROM_TIMETABLE = 'SET_ARRIVAL_FROM_TIMETABLE';

export function setArrivalFromTimetable(dateAndTime) {
  return {
    type: SET_ARRIVAL_FROM_TIMETABLE,
    payload: {
      dateAndTime: dateAndTime
    }
  }
}
