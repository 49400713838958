// Set prices when quotes have been returned
import Api from '../../../api/index';

export const SUBMIT_USER_INFO = 'SUBMIT_USER_INFO';
export const SUBMIT_USER_INFO_SUCCESS = 'SUBMIT_USER_INFO_SUCCESS';
export const LEAVE_USER_INFO_FORM = 'LEAVE_USER_INFO_FORM';

export function submitUserInfo(name, phone, email, pickupDateAndTime) {
  return (dispatch, getState) => {

    // Params
    const params = {
      name: name,
      email: email,
      phone: phone,
      pickup: pickupDateAndTime? pickupDateAndTime.replace('T', ' ') : '',
      utmArray: getState().tracking.utmArray
    };

    const payload = Object.assign({}, params);

    if (name) {
      payload.firstName = name.split(' ')[0];
      payload.lastName = name.split(' ')[1] || '';
    }

    // Save in redux store - also saved in Booking reducer
    dispatch({
      type: SUBMIT_USER_INFO,
      payload: payload
    });

    Api.post('website/user-details', params)
      .then(() => {
        dispatch({
          type: SUBMIT_USER_INFO_SUCCESS
        })
      })
  }
}

export function leaveUserInfoForm() {
  return {
    type: LEAVE_USER_INFO_FORM
  }
}
