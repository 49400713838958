// Set prices when quotes have been returned
import Api from "../../../api/index";
import { setCurrentModal } from "../../modals/Modal/ModalActions";
import {
  EDIT_DROPOFF_ADDRESS_MODAL,
  EDIT_PICKUP_ADDRESS_MODAL,
} from "../../../library/modals";
import { estimateNewDistance } from "../../../actions/DistanceActions";
import { setNumberOfStairs } from "../../bookingStep2/Customise/CustomiseActions";
export const SELECT_ADDRESS_SUGGESTION = "SELECT_ADDRESS_SUGGESTION";
export const SUGGESTIONS_FOUND = "SUGGESTIONS_FOUND";
export const POPULATE_ADDRESS = "POPULATE_ADDRESS";
export const POSTCODE_INVALID = "POSTCODE_INVALID";
export const CLEAR_POSTCODE_ERROR = "CLEAR_POSTCODE_ERROR";
export const SAVE_STAIRS_INDEX_FOR_PICKUP_AND_DROPOFF =
  "SAVE_STAIRS_INDEX_FOR_PICKUP_AND_DROPOFF";

export const POPULATE_ADDITIONAL_STOP_ADDRESS =
  "POPULATE_ADDITIONAL_STOP_ADDRESS"; // todo: refactor this ...

export function saveStairs(pickupStairs, dropoffStairs) {
  return (dispatch, getState) => {
    dispatch({
      type: SAVE_STAIRS_INDEX_FOR_PICKUP_AND_DROPOFF,
      payload: {
        pickupStairs,
        dropoffStairs,
      },
    });

    // const stairsCount = pickupStairs + dropoffStairs;

    const {
      pickupWithLift,
      dropoffWithLift,
    } = getState().bookingPricesCustomChoices;

    let stairsCount = 0;

    if (!pickupWithLift) {
      stairsCount = pickupStairs + stairsCount;
    }

    if (!dropoffWithLift) {
      stairsCount = dropoffStairs + stairsCount;
    }

    dispatch(setNumberOfStairs(stairsCount));
  };
}

export function fetchSuggestions(whichAddress, input) {
  return (dispatch) => {
    return Api.post("w/address-autocomplete", { line: input }).then((resp) => {
      let suggestions = [];
      if (resp.message === "Success" && resp.result && resp.result.hits)
        suggestions = resp.result.hits;

      dispatch({
        type: SUGGESTIONS_FOUND,
        payload: {
          whichAddress: whichAddress,
          suggestions: suggestions,
        },
      });
    });
  };
}

export function editAdditionalStopAddress(index) {
  return setCurrentModal(`EDIT_ADDITIONAL_STOP_${index}_ADDRESS_MODAL`);
}

export function editAddress(whichAddress) {
  return (dispatch) => {
    if (whichAddress === "pickup") {
      dispatch(setCurrentModal(EDIT_PICKUP_ADDRESS_MODAL));
    } else {
      dispatch(setCurrentModal(EDIT_DROPOFF_ADDRESS_MODAL));
    }
  };
}

export function selectAddress(whichAddress, hit) {
  return (dispatch) => {
    dispatch({
      type: SELECT_ADDRESS_SUGGESTION,
      payload: {
        whichAddress: whichAddress,
      },
    });

    dispatch(chooseLocation(whichAddress, hit));
  };
}

export function chooseLocation(whichAddress, hit, additionalStopIndex) {
  return (dispatch) => {
    return Api.post("w/address-details", { udprn: hit.udprn }).then((resp) => {
      if (resp.message === "Success") {
        const addressObj = {
          line_1: resp.result.line_1,
          line_2: resp.result.line_2,
          line_3: resp.result.line_3,
          post_town: resp.result.post_town,
          postcode: resp.result.postcode,
          longitude: resp.result.longitude,
          latitude: resp.result.latitude,
        };

        const fullAddress = [
          addressObj.line_1,
          addressObj.line_2,
          addressObj.line_3,
          addressObj.post_town,
          addressObj.postcode,
        ]
          .filter((val) => val && val.length > 0)
          .join(", ");

        if (typeof additionalStopIndex === "number") {
          dispatch({
            type: POPULATE_ADDITIONAL_STOP_ADDRESS,
            payload: {
              additionalStopIndex: additionalStopIndex,
              fullAddress: fullAddress,
              line_1: addressObj.line_1,
              line_2: addressObj.line_2,
              line_3: addressObj.line_3,
              post_town: addressObj.post_town,
              postcode: addressObj.postcode,
              longitude: addressObj.longitude,
              latitude: addressObj.latitude,
            },
          });

          dispatch(estimateNewDistance());
        } else {
          dispatch({
            type: POPULATE_ADDRESS,
            payload: {
              whichAddress: whichAddress,
              fullAddress: fullAddress,
              line_1: addressObj.line_1,
              line_2: addressObj.line_2,
              line_3: addressObj.line_3,
              post_town: addressObj.post_town,
              postcode: addressObj.postcode,
              longitude: addressObj.longitude,
              latitude: addressObj.latitude,
            },
          });

          dispatch(validatePostcode(whichAddress, addressObj.postcode));
        }
      }
    });
  };
}

export function validatePostcode(whichAddress, postcode) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_POSTCODE_ERROR,
      payload: {
        whichAddress: whichAddress,
      },
    });

    return Api.post("w/postcode/validate", { postcode }).then((resp) => {
      if (resp.err) {
        return;
      }

      if (resp.isValid) {
        if (resp["availableInArea"] === false && whichAddress === "pickup") {
          dispatch({
            type: POSTCODE_INVALID,
            payload: {
              whichAddress: whichAddress,
              errorMessage: "JJD is not available in your area yet",
            },
          });
          return;
        }

        // Valid change of address - estimate new distance:
        dispatch(estimateNewDistance());
      } else {
        dispatch({
          type: POSTCODE_INVALID,
          payload: {
            whichAddress: whichAddress,
            errorMessage: "Please enter a valid postcode",
          },
        });
      }
    });
  };
}
