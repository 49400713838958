import Api from '../../../api/index';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export const CHECK_VERIFICATION_CODE = 'CHECK_VERIFICATION_CODE';
export const CHECK_VERIFICATION_CODE_FAILED = 'CHECK_VERIFICATION_CODE_FAILED';
export const CHECK_VERIFICATION_CODE_SUCCESS = 'CHECK_VERIFICATION_CODE_SUCCESS';
export const SET_CARD_DETAILS_FROM_LOGIN = 'SET_CARD_DETAILS_FROM_LOGIN';
export const RESET_VERIFY_MOBILE = 'RESET_VERIFY_MOBILE';
export const SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN = 'SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN';

export function checkVerificationCode(code) {
  return (dispatch, getState) => {

    dispatch({
      type: CHECK_VERIFICATION_CODE
    });

    const params = {
      phone: getState().userDetails.mobileNumber,
      firstName: getState().userDetails.firstName,
      lastName: getState().userDetails.lastName,
      code: code,
      email: getState().userDetails.email,
      utmArray: getState().tracking.utmArray
    };

    Api.post('w/login/web-phone-login', params)
      .then(resp => {
        if (resp.err) {

          ReactGA.event({
            category: 'Web booking',
            action: 'Check verification code failed',
            label: resp.err
          });

          dispatch({
            type: CHECK_VERIFICATION_CODE_FAILED,
            payload: {
              errorMessage: resp.err || 'Failed'
            }
          });

          return;
        }

        ReactGA.event({
          category: 'Web booking',
          action: 'Check verification code success',
          label: 'User registered'
        });
        ReactPixel.track('Lead');

        dispatch({
          type: CHECK_VERIFICATION_CODE_SUCCESS
        });

        dispatch({
          type: SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN,
          payload: {
            firstName: resp.firstName,
            lastName: resp.lastName,
            email: resp.email,
            phone: resp.phone,
            phoneValidated: resp.phoneValidated,
            mp_distinct_id: resp.mp_distinct_id,
            hasWebLogin: resp.hasWebLogin
          }
        });

        if (resp.card) {
          dispatch({
            type: SET_CARD_DETAILS_FROM_LOGIN,
            payload: {
              card: resp.card
            }
          })
        }

      })

  }
}

export function resetVerifyMobile() {
  return {
    type: RESET_VERIFY_MOBILE
  }
}
