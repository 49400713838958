// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-choose-van-size-js": () => import("./../../../src/pages/choose-van-size.js" /* webpackChunkName: "component---src-pages-choose-van-size-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-drivers-js": () => import("./../../../src/pages/drivers.js" /* webpackChunkName: "component---src-pages-drivers-js" */),
  "component---src-pages-easy-booking-js": () => import("./../../../src/pages/easy-booking.js" /* webpackChunkName: "component---src-pages-easy-booking-js" */),
  "component---src-pages-easy-booking-success-js": () => import("./../../../src/pages/easy-booking-success.js" /* webpackChunkName: "component---src-pages-easy-booking-success-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-js": () => import("./../../../src/pages/instructions.js" /* webpackChunkName: "component---src-pages-instructions-js" */),
  "component---src-pages-man-and-van-app-js": () => import("./../../../src/pages/man-and-van-app.js" /* webpackChunkName: "component---src-pages-man-and-van-app-js" */),
  "component---src-pages-options-js": () => import("./../../../src/pages/options.js" /* webpackChunkName: "component---src-pages-options-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-remind-me-later-js": () => import("./../../../src/pages/remind-me-later.js" /* webpackChunkName: "component---src-pages-remind-me-later-js" */),
  "component---src-pages-reserve-js": () => import("./../../../src/pages/reserve.js" /* webpackChunkName: "component---src-pages-reserve-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-247-man-and-van-js": () => import("./../../../src/pages/services/247-man-and-van.js" /* webpackChunkName: "component---src-pages-services-247-man-and-van-js" */),
  "component---src-pages-services-furniture-courier-js": () => import("./../../../src/pages/services/furniture-courier.js" /* webpackChunkName: "component---src-pages-services-furniture-courier-js" */),
  "component---src-pages-services-house-removals-js": () => import("./../../../src/pages/services/house-removals.js" /* webpackChunkName: "component---src-pages-services-house-removals-js" */),
  "component---src-pages-services-moving-flat-js": () => import("./../../../src/pages/services/moving-flat.js" /* webpackChunkName: "component---src-pages-services-moving-flat-js" */),
  "component---src-pages-services-office-moves-js": () => import("./../../../src/pages/services/office-moves.js" /* webpackChunkName: "component---src-pages-services-office-moves-js" */),
  "component---src-pages-services-piano-movers-js": () => import("./../../../src/pages/services/piano-movers.js" /* webpackChunkName: "component---src-pages-services-piano-movers-js" */),
  "component---src-pages-services-pickup-from-store-js": () => import("./../../../src/pages/services/pickup-from-store.js" /* webpackChunkName: "component---src-pages-services-pickup-from-store-js" */),
  "component---src-pages-services-same-day-delivery-js": () => import("./../../../src/pages/services/same-day-delivery.js" /* webpackChunkName: "component---src-pages-services-same-day-delivery-js" */),
  "component---src-pages-set-arrival-time-js": () => import("./../../../src/pages/set-arrival-time.js" /* webpackChunkName: "component---src-pages-set-arrival-time-js" */),
  "component---src-pages-shpock-js": () => import("./../../../src/pages/shpock.js" /* webpackChunkName: "component---src-pages-shpock-js" */),
  "component---src-pages-successful-booking-js": () => import("./../../../src/pages/successful-booking.js" /* webpackChunkName: "component---src-pages-successful-booking-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-your-inventory-js": () => import("./../../../src/pages/your-inventory.js" /* webpackChunkName: "component---src-pages-your-inventory-js" */)
}

