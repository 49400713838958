import _ from "lodash";
import Api from "../../../api/index";
import Utils from "../../../library/utils";
import { LIFTING_CHOICES_API } from "../../../library/lifting";
import { VANS, CUSTOM_VAN_CHOICES } from "../../../library/vans";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

export const PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS";
export const MAKE_PAYMENT_FAILED = "MAKE_PAYMENT_FAILED";
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
export const RESET_CARD_INFO = "RESET_CARD_INFO";
export const SET_ONE_USE_TOKEN = "SET_ONE_USE_TOKEN";
export const RESET_BOOKING_PAYMENT_ERRORS = "RESET_BOOKING_PAYMENT_ERRORS";
export const RESET_PAYMENT_REDUCER = "RESET_PAYMENT_REDUCER";
export const PAYMENT_REQUIRES_SCA_AUTH = "PAYMENT_REQUIRES_SCA_AUTH";

export function changeCard() {
  return {
    type: RESET_CARD_INFO,
  };
}

export function resetPaymentStuff() {
  return {
    type: RESET_PAYMENT_REDUCER,
  };
}

export function resetPaymentErrors() {
  return {
    type: RESET_BOOKING_PAYMENT_ERRORS,
  };
}

export function makePaymentAsGuest(
  payment_method_id,
  payment_intent_id,
  paypalData,
  paymentType
) {
  return (dispatch, getState) => {
    dispatch({
      type: PAYMENT_IN_PROGRESS,
    });

    const bookingAddresses = Object.assign({}, getState().bookingAddresses);
    const bookingGeneral = Object.assign({}, getState().bookingGeneral);
    const bookingDistance = Object.assign({}, getState().bookingDistance);
    const bookingPrice = Object.assign({}, getState().bookingPrice);
    const bookingPayment = Object.assign({}, getState().bookingPayment);
    const bookingPricesCustomChoices = Object.assign(
      {},
      getState().bookingPricesCustomChoices
    );

    const userDetails = Object.assign({}, getState().userDetails);
    const selectedVanOption = CUSTOM_VAN_CHOICES[bookingGeneral.vanSizeIndex];
    const jobDescription = Utils.isBlank(bookingGeneral.jobDescription)
      ? "No job description"
      : bookingGeneral.jobDescription;
    const params = {
      jobDescription: jobDescription,
      oneUseToken: bookingPayment.oneUseToken,
      pickupAddress: {
        number: bookingAddresses.pickup.line_1,
        street: [bookingAddresses.pickup.line_2, bookingAddresses.pickup.line_3]
          .filter((val) => val && val.length > 0)
          .join(", "),
        town: bookingAddresses.pickup.post_town,
        postcode: bookingAddresses.pickup.postcode,
      },
      dropoffAddress: {
        number: bookingAddresses.dropoff.line_1,
        street: [
          bookingAddresses.dropoff.line_2,
          bookingAddresses.pickup.line_3,
        ]
          .filter((val) => val && val.length > 0)
          .join(", "),
        town: bookingAddresses.dropoff.post_town,
        postcode: bookingAddresses.dropoff.postcode,
      },
      pickup: bookingGeneral.arrival,
      size: VANS[bookingGeneral.vanSizeIndex].apiName,
      liftingPower:
        selectedVanOption.possibleOptions[
          bookingPricesCustomChoices.vanSizes[selectedVanOption.vanSize]
            .selectedHelperIndex
        ].helperCount,
      liftingPowerIndex: bookingGeneral.helpLiftingIndex,
      rideAlong: _.isBoolean(bookingGeneral.rideAlong)
        ? 0
        : bookingGeneral.rideAlong,
      requiredTime: bookingDistance.minimumTimeHours + bookingGeneral.extraTime,
      name:
        bookingGeneral.name ||
        VANS[bookingGeneral.vanSizeIndex].name + " van job", // name of service ?...
      stairs: bookingGeneral.noOfStairs,
      pickupName: userDetails.firstName + " " + userDetails.lastName,
      pickupPhone: userDetails.mobileNumber,
      dropoffName: userDetails.firstName + " " + userDetails.lastName,
      dropoffPhone: userDetails.mobileNumber,
      utmArray: getState().tracking.utmArray,
      inventory: bookingGeneral.inventory,
      // Guest booking additional params:
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phone: userDetails.mobileNumber,
      pickupHasLift: bookingPricesCustomChoices.pickupWithLift,
      pickupStairs: bookingPricesCustomChoices.pickupStairs,
      dropoffHasLift: bookingPricesCustomChoices.dropoffWithLift,
      dropoffStairs: bookingPricesCustomChoices.dropoffStairs,
      payment_type: paymentType,
      /* estimate: Utils.getFinalEstimate(
        bookingGeneral.vanSizeIndex,
        bookingPricesCustomChoices
      ), */
      // token: bookingPayment.guestPaymentToken - deprecated
    };

    if (payment_method_id) params.payment_method_id = payment_method_id;
    if (payment_intent_id) params.payment_intent_id = payment_intent_id;
    if (paypalData) params.paypal = paypalData;
    // Collection range (optional)
    const collectionRange = getState().bookingGeneral.collectionRange;
    if (collectionRange) params.collectionRange = collectionRange;

    // Additional stops
    const additionalStops = getState().bookingAdditionalStops.stops;
    if (additionalStops.length > 0) {
      const formattedAdditionalStops = [];
      for (let i = 0; i < additionalStops.length; i++) {
        const formattedAddress = {
          number: additionalStops[i].line_1,
          street: [additionalStops[i].line_2, additionalStops[i].line_3]
            .filter((val) => val && val.length > 0)
            .join(", "),
          town: additionalStops[i].post_town,
          postcode: additionalStops[i].postcode,
        };
        formattedAdditionalStops.push(formattedAddress);
      }
      params.additionalStops = formattedAdditionalStops;
    }

    Api.post("w/guest-booking", params)
      .then((resp) => {
        // console.log({ resp });
        if (resp.err) {
          dispatch({
            type: MAKE_PAYMENT_FAILED,
            payload: {
              errorMessage: _.isString(resp.err)
                ? resp.err
                : "Something went wrong",
            },
          });

          dispatch(setOneUseToken());

          ReactGA.event({
            category: "Web booking",
            action: "Payment failed",
            label: resp.err,
          });

          return;
        }

        if (resp.requires_action) {
          dispatch({
            type: PAYMENT_REQUIRES_SCA_AUTH,
            payload: {
              payment_intent_client_secret: resp.payment_intent_client_secret,
              payment_intent_id: resp.payment_intent_id, // todo: this isn't used anywhere at the moment ...
            },
          });

          return;
        }

        ReactGA.event({
          category: "Web booking",
          action: "Has successfully completed payment",
          label: "Successful booking",
        });
        ReactPixel.track("Purchase");

        dispatch({
          type: MAKE_PAYMENT_SUCCESS,
        });

        // Moved here - could have been breaking payment ?
        ReactGA.event({
          category: "Web booking",
          action: "Deposited",
          label: (parseInt(bookingPrice.deposit) / 100).toString(), // track amount
        });
      })
      .catch((err) => {
        ReactGA.event({
          category: "Web booking",
          action: "Payment failed",
          label: err.message,
        });

        dispatch({
          type: MAKE_PAYMENT_FAILED,
          payload: {
            errorMessage: err.message,
          },
        });

        dispatch(setOneUseToken());
      });
  };
}

export function setOneUseToken() {
  const token = Utils.generateGuid();
  return {
    type: SET_ONE_USE_TOKEN,
    payload: {
      token: token,
    },
  };
}
