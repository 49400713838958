import Api from '../../../api/index';
import ReactGA from 'react-ga';

export const SETTING_PASSWORD = 'SETTING_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILED = 'SET_PASSWORD_FAILED';

export function createPassword(password) {
  return dispatch => {

    dispatch({
      type: SETTING_PASSWORD
    });

    const params = {password: password};
    Api.post('w/login/set-password', params)
      .then(resp => {

        if (resp.err) {

          dispatch({
            type: SET_PASSWORD_FAILED,
            payload: {
              errorMessage: resp.err
            }
          });

          return;
        }

        ReactGA.event({
          category: 'Web booking',
          action: 'Has created password for email login',
          label: 'Registered with email and password'
        });

        dispatch({
          type: SET_PASSWORD_SUCCESS
        })

      })

  }
}
