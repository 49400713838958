// pickup - ’string’ - format "YYYY-MM-DD HH:mm” - time the van should be reserved for - REQUIRED
// size - ’string’ - van size - ‘Medium’ | ‘Long’ | ‘Luton’ - REQUIRED
// phone - ’string’ - mobile phone number - either 07123123123 or 447123123123 - REQUIRED
// email - ’string’ - Must be email formatted - REQUIRED

import Api from '../../../api';
import ReactGA from 'react-ga';

export const MAKING_RESERVATION = 'MAKING_RESERVATION';
export const SET_USER_DETAILS_FROM_RESERVATION_MAKER = 'SET_USER_DETAILS_FROM_RESERVATION_MAKER';
export const RESET_RESERVATION = 'RESET_RESERVATION';
export const FAILED_TO_MAKE_RESERVATION = 'FAILED_TO_MAKE_RESERVATION';
export const RESERVATION_MADE = 'RESERVATION_MADE';

export function resetReservation() {
  return {
    type: RESET_RESERVATION
  }
}

export function makeReservation(mobileNumber, email, pickup) {
  return (dispatch, getState) => {
    dispatch({
      type: MAKING_RESERVATION
    });

    dispatch({
      type: SET_USER_DETAILS_FROM_RESERVATION_MAKER,
      payload: {
        mobileNumber: mobileNumber,
        email: email
      }
    });

    const params = {
      pickup: pickup,
      size: 'Luton',
      phone: mobileNumber,
      email: email,
      utmArray: getState().tracking.utmArray
    };

    Api.post('w/reservation/create', params)
      .then(resp => {
        if (resp.err) {
          dispatch({
            type: FAILED_TO_MAKE_RESERVATION,
            payload: {
              errorMessage: resp.err
            }
          });

          ReactGA.event({
            category: 'Reservation',
            action: 'Failed to make reservation',
            label: resp.err
          });

          return
        }

        dispatch({
          type: RESERVATION_MADE,
          payload: {
            reservationId: resp.reservationId
          }
        });

        ReactGA.event({
          category: 'Reservation',
          action: 'Reservation made successfully',
          label: 'Reservation made'
        });
      })
      .catch(() => {
        dispatch({
          type: FAILED_TO_MAKE_RESERVATION,
          payload: {
            errorMessage: 'Failed to make reservation'
          }
        });

        ReactGA.event({
          category: 'Reservation',
          action: 'Failed to make reservation',
          label: 'Catch error'
        });
      })

  }
}
