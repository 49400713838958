import Api from "../../../api";
import { checkIfUserIsLoggedIn } from "../../auth/Auth/AuthActions";

export const SET_CSRF_TOKEN = "SET_CSRF_TOKEN";

export function setupCsrf() {
  return (dispatch) => {
    Api.get("csrfToken").then((resp) => {
      dispatch({
        type: SET_CSRF_TOKEN,
        payload: {
          _csrf: resp._csrf,
        },
      });

      dispatch(checkIfUserIsLoggedIn());
    });
  };
}
