export const LINKS = {
  // Android: 'https://play.google.com/store/apps/details?id=com.kiffgoapp',
  // iOS: 'https://itunes.apple.com/gb/app/man-and-van-kiffgo/id1274010299',
  Facebook: 'https://web.facebook.com/jjdvan/?_rdc=1&_rdr',
  // Twitter: 'https://twitter.com/kiffgo',
  Instagram: 'https://www.instagram.com/jjd_vans',
  Youtube: 'https://www.youtube.com/channel/UCZdVOXYFSGOcHbsz1FfOJBg?disable_polymer=true',
  Trustpilot: 'https://www.trustpilot.com/review/jjdvan.com',
  // Bark: 'https://www.bark.com/en/company/kiffgo-ltd/3VElg/',
  // MediumTechBlog: 'https://medium.com/kiffgo',

  // New:
  // OneLink: 'https://kiffgo.onelink.me/B76U'
};
