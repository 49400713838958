import update from 'immutability-helper';
import {
  FAILED_TO_MAKE_RESERVATION, MAKING_RESERVATION, RESERVATION_MADE,
  RESET_RESERVATION
} from '../containers/modals/ReserveBooking/ReserveBookingModalActions';
import { SET_ARRIVAL_FROM_TIMETABLE } from '../containers/reserve/Timetable/TimetableActions';
import {
  PAYING_FOR_RESERVATION,
  RESERVATION_PAYMENT_FAILED,
  RESERVATION_PAYMENT_SUCCESSFUL
} from '../containers/reserve/StripeMakePayment/StripeMakePaymentActions';
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  arrival: null, // DatePickerAndCollection time - the rest is saved in userDetails (email and phone)

  // Grab details:
  reservationMade: false,
  makingReservation: false,
  makingReservationError: null,

  // Made reservation:
  reservationId: null,

  // Paying
  paymentSuccessful: false,
  paymentFailedError: null,
  paymentInProgress: false
};

function reservation(state = initialState, action) {
  switch (action.type) {
    case RESERVATION_PAYMENT_SUCCESSFUL:
      return update(state, {
        paymentSuccessful: {$set: true},
        paymentInProgress: {$set: false}
      });
    case RESERVATION_PAYMENT_FAILED:
      return update(state, {
        paymentFailedError: {$set: action.payload.errorMessage},
        paymentInProgress: {$set: false}
      });
    case PAYING_FOR_RESERVATION:
      return update(state, {
        paymentInProgress: {$set: true}
      });
    case RESERVATION_MADE:
      return update(state, {
        reservationMade: {$set: true},
        makingReservation: {$set: false},
        makingReservationError: {$set: null},
        reservationId: {$set: action.payload.reservationId}
      });
    case SET_ARRIVAL_FROM_TIMETABLE:
      return update(state, {
        arrival: {$set: action.payload.dateAndTime}
      });
    case MAKING_RESERVATION:
      return update(state, {
        makingReservation: {$set: true}
      });
    case FAILED_TO_MAKE_RESERVATION:
      return update(state, {
        makingReservationError: {$set: action.payload.errorMessage},
        makingReservation: {$set: false}
      });
    case RESET_RESERVATION:
      return Object.assign({}, initialState);
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default reservation;
