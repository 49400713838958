import Api from "../../../api";

export const IS_LOGGED_IN = "IS_LOGGED_IN";

export function checkIfUserIsLoggedIn() {
  return (dispatch) => {
    Api.post("w/user/is-logged-in").then((resp) => {
      if (resp.isLoggedIn) {
        dispatch({
          type: IS_LOGGED_IN,
        });
      }
    });
  };
}
