import update from "immutability-helper";
import { RESET_BOOKING } from "../containers/bookingStep5/BookingSuccess/BookingSuccessActions";
import {
  ADD_ADDITIONAL_STOP,
  ADDITIONAL_STOP_POSTCODE_INVALID,
  ADDITIONAL_STOP_POSTCODE_IS_VALID,
  CLEAR_ADDITIONAL_STOP_POSTCODE_ERROR,
  REMOVE_ADDITIONAL_STOP,
  REMOVE_ALL_ADDITIONAL_STOPS,
} from "../actions/AdditionalStopsActions";
import { POPULATE_ADDITIONAL_STOP_ADDRESS } from "../containers/bookingStep0/RoutePlanner/RoutePlannerActions";
import { SAVE_ADDITIONAL_STOP_ADDRESS_MANUALLY } from "../containers/modals/Address/AddressModalActions";
import { POPULATE_ADDITIONAL_STOPS_FROM_EASY_BOOKING } from "../containers/easyBooking/EasyBooking/EasyBookingActions";

const emptyAddress = {
  fullAddress: "",
  line_1: "",
  line_2: "",
  line_3: "",
  post_town: "",
  postcode: "",
  selectedFromSuggestion: false,
  postcodeValid: false,
  postcodeError: null,
  longitude: "",
  latitude: "",
};

const initialState = {
  // RoutePlanner:
  stops: [], // none, but will be added
  postcodeError: null,
};

function bookingAddresses(state = initialState, action) {
  switch (action.type) {
    case POPULATE_ADDITIONAL_STOPS_FROM_EASY_BOOKING:
      return update(state, {
        stops: { $set: action.payload },
      });
    case REMOVE_ALL_ADDITIONAL_STOPS:
      return update(state, {
        stops: { $set: [] },
      });
    case CLEAR_ADDITIONAL_STOP_POSTCODE_ERROR:
      return update(state, {
        postcodeError: { $set: null },
      });
    case ADDITIONAL_STOP_POSTCODE_INVALID:
      return update(state, {
        postcodeError: { $set: action.payload.errorMessage },
      });
    case ADDITIONAL_STOP_POSTCODE_IS_VALID:
      return update(state, {
        stops: {
          [action.payload.stopIndex]: {
            postcodeValid: { $set: true },
          },
        },
      });
    case SAVE_ADDITIONAL_STOP_ADDRESS_MANUALLY:
      return update(state, {
        stops: {
          [action.payload.stopIndex]: {
            fullAddress: { $set: action.payload.fullAddress },
            line_1: { $set: action.payload.line_1 },
            line_2: { $set: action.payload.line_2 },
            line_3: { $set: action.payload.line_3 },
            post_town: { $set: action.payload.post_town },
            postcode: { $set: action.payload.postcode },
          },
        },
      });
    case POPULATE_ADDITIONAL_STOP_ADDRESS:
      return update(state, {
        stops: {
          [action.payload.additionalStopIndex]: {
            fullAddress: { $set: action.payload.fullAddress },
            line_1: { $set: action.payload.line_1 },
            line_2: { $set: action.payload.line_2 },
            line_3: { $set: action.payload.line_3 },
            post_town: { $set: action.payload.post_town },
            postcode: { $set: action.payload.postcode },
            selectedFromSuggestion: { $set: true },
            longitude: { $set: action.payload.longitude },
            latitude: { $set: action.payload.latitude },
          },
        },
      });
    case ADD_ADDITIONAL_STOP:
      return update(state, {
        stops: { $push: [Object.assign({}, emptyAddress)] },
      });
    case REMOVE_ADDITIONAL_STOP:
      return update(state, {
        stops: { $splice: [[action.payload, 1]] }, // payload is index of additional stop ...
      });
    case RESET_BOOKING:
      return initialState;
    default:
      return state;
  }
}

export default bookingAddresses;
