import update from 'immutability-helper';
import { CALCULATE_PRICE_SUCCESS } from '../actions/PriceActions';
import { RESET_BOOKING, WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';
import {
  MAKE_PAYMENT_FAILED,
  MAKE_PAYMENT_SUCCESS,
  PAYMENT_IN_PROGRESS, PAYMENT_REQUIRES_SCA_AUTH,
  RESET_BOOKING_PAYMENT_ERRORS,
  RESET_CARD_INFO, RESET_PAYMENT_REDUCER,
  SET_ONE_USE_TOKEN
} from '../containers/bookingStep4/Payment/PaymentActions';
import {
  RECEIVE_TOKEN_FOR_GUEST_PAYMENT,
  SET_CARD_DETAILS_AND_PAY
} from '../containers/bookingStep4/StripeAddCard/StripeAddCardActions';

const initialState = {
  oneUseToken: null,
  payingFromAddCard: false,
  paymentInProgress: false,
  paymentFailedError: null,
  paymentSuccessful: false,
  guestPaymentToken: null,
  requiresScaAuth: null
};

function bookingPayment(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_REQUIRES_SCA_AUTH:
      return update(state, {
        requiresScaAuth: {$set: action.payload.payment_intent_client_secret}
      });
    case RECEIVE_TOKEN_FOR_GUEST_PAYMENT:
      return update(state, {
        guestPaymentToken: {$set: action.payload.token},
        paymentInProgress: {$set: true}
      });
    case RESET_BOOKING_PAYMENT_ERRORS:
      return update(state, {
        oneUseToken: {$set: null},
        payingFromAddCard: {$set: false},
        paymentInProgress: {$set: false},
        paymentFailedError: {$set: null},
        paymentSuccessful: {$set: false}
      });
    case SET_CARD_DETAILS_AND_PAY:
      return update(state, {
        payingFromAddCard: {$set: true}
      });
    case CALCULATE_PRICE_SUCCESS:
      return update(state, {
        allBookingDetailsValid: {$set: true},
      });
    case PAYMENT_IN_PROGRESS:
      return update(state, {
        paymentInProgress: {$set: true},
        paymentFailedError: {$set: null}
      });
    case MAKE_PAYMENT_FAILED:
      return update(state, {
        paymentInProgress: {$set: false},
        paymentFailedError: {$set: action.payload.errorMessage},
        guestPaymentToken: {$set: null}
      });
    case MAKE_PAYMENT_SUCCESS:
      return update(state, {
        paymentInProgress: {$set: false},
        paymentFailedError: {$set: null},
        paymentSuccessful: {$set: true}
      });
    case RESET_CARD_INFO:
      return update(state, {
        paymentInProgress: {$set: false},
        paymentFailedError: {$set: null},
        paymentSuccessful: {$set: false}
      });
    case SET_ONE_USE_TOKEN:
      return update(state, {
        oneUseToken: {$set: action.payload.token}
      });
    case RESET_PAYMENT_REDUCER:
      return initialState;
    case RESET_BOOKING:
      return initialState; // also removes one use token
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default bookingPayment;
