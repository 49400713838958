import update from 'immutability-helper';
import { SET_CURRENT_HOW_IT_WORKS_STEP } from '../containers/sections/AppWalkthrough/AppWalkthroughActions';

const initialState = {
  selectedStep: 0
};

function howItWorks(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_HOW_IT_WORKS_STEP:
      return update(state, {
        selectedStep: {$set: action.payload}
      });
    default:
      return state
  }
}

export default howItWorks;
