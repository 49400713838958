// const host = 'https://api.kiffgo.com/';
// const host = 'https://kiffgo-staging.herokuapp.com/';
// const host = 'http://localhost:1337/';

import { store } from '../Store';
import { SET_CSRF_TOKEN } from '../containers/bootstrap/Security/SecurityActions';
const host = `${process.env.GATSBY_API_URL}`;
export const SESSION_TIMED_OUT = 'SESSION_TIMED_OUT';

class Api {
  static headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json'
    }
  }

  static get(route) {
    return this.xhr(route, null, 'GET');
  }

  static put(route, params) {
    return this.xhr(route, params, 'PUT')
  }

  static post(route, params) {
    return this.xhr(route, params, 'POST');
  }

  static delete(route, params) {
    return this.xhr(route, params, 'DELETE')
  }

  static logline(logline) {
    return this.xhr('log', {logline}, 'POST')
  }

  static xhr(route, params, verb) {
    // CSRF:
    if (verb !== 'GET') {
      params = Object.assign({}, params);
      params._csrf = store.getState().auth._csrf;
    }

    const url = `${host}${route}`;
    let options = Object.assign({method: verb}, params ? {body: JSON.stringify(params)} : null);
    options.credentials = 'include';
    options.headers = Api.headers();

    return new Promise((resolve, reject) => {
      fetch(url, options).then(resp => {
        let json = resp.json();

        if (resp.ok) {
          resolve(json)
        }

        if (resp.status === 403) { // CSRF Mismatch error

          store.dispatch({type: SESSION_TIMED_OUT}); // Need to re-authenticate

          this.get('csrfToken')
            .then((resp) => {
              params._csrf = resp._csrf;
              store.dispatch({type: SET_CSRF_TOKEN, payload: {_csrf: resp._csrf}});
              this.xhr(route, params, verb, true).then((resp) => {
                resolve(resp);
              })
            })
        } else { // General error
          json.then(err => {
            reject(err)
          })
            .catch(err => {
              reject(err)
            });
        }
      });
    });
  }
}

export default Api
