import update from "immutability-helper";
import {
  CALCULATE_PRICE,
  CALCULATE_PRICE_FAILED,
  CALCULATE_PRICE_SUCCESS,
} from "../actions/PriceActions";
import {
  CALCULATE_DISTANCE,
  ERROR_UPDATING_DISTANCE,
} from "../actions/DistanceActions";
import { RESET_BOOKING } from "../containers/bookingStep5/BookingSuccess/BookingSuccessActions";

const initialState = {
  estimate: null,
  deposit: 0,
  reward: 0,
  extraChargeMins: 30,
  minimumTimeHours: 2,
  extraChargeValue: 0,
  duration: 0,
  distance: 0,
};

function bookingPrice(state = initialState, action) {
  switch (action.type) {
    /* case CALCULATE_DISTANCE:
      return update(state, {
        estimatingNewPrice: { $set: true },
      });
    case ERROR_UPDATING_DISTANCE:
      return update(state, {
        estimatingNewPrice: { $set: false },
      });
    case CALCULATE_PRICE:
      return update(state, {
        estimatingNewPrice: { $set: true },
      });
    case CALCULATE_PRICE_FAILED:
      return update(state, {
        estimatingNewPrice: { $set: false },
      }); */
    case CALCULATE_PRICE_SUCCESS:
      return update(state, {
        // estimatingNewPrice: { $set: false },
        estimate: { $set: action.payload.resp.estimate },
        deposit: { $set: action.payload.resp.deposit },
        reward: { $set: action.payload.resp.reward },
        distance: { $set: action.payload.resp.distance },
        duration: { $set: action.payload.resp.duration },
        estimatePeriodHours: { $set: action.payload.resp.estimatePeriodHours },
        extraChargeMins: { $set: action.payload.resp.extraChargeMins },
        extraChargeValue: { $set: action.payload.resp.extraChargeValue },
        minimumTimeHours: { $set: action.payload.resp.minimumTimeHours },
      });
    case RESET_BOOKING:
      return initialState; // also removes one use token
    default:
      return state;
  }
}

export default bookingPrice;
