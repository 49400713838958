import Utils from "../library/utils";
import { VANS, CUSTOM_VAN_CHOICES } from "../library/vans";
import { LIFTING_CHOICES_API } from "../library/lifting";
import Api from "../api";
import { object } from "prop-types";

export function saveLead(mobileNumber) {
  return (dispatch, getState) => {
    const bookingAddresses = Object.assign({}, getState().bookingAddresses);
    const bookingGeneral = Object.assign({}, getState().bookingGeneral);
    const bookingPricesCustomChoices = Object.assign({}, getState().bookingPricesCustomChoices);
    const bookingDistance = Object.assign({}, getState().bookingDistance);
    const jobDescription = Utils.isBlank(bookingGeneral.jobDescription)
      ? "No job description"
      : bookingGeneral.jobDescription;
    const userDetails = Object.assign({}, getState().userDetails);

    if (bookingGeneral.vanSizeIndex == null || bookingGeneral.vanSizeIndex == undefined) {

      const paramsInit={
        phone:mobileNumber,
        pickupAddress: {
          number: bookingAddresses.pickup.line_1,
          street: [bookingAddresses.pickup.line_2, bookingAddresses.pickup.line_3]
            .filter((val) => val && val.length > 0)
            .join(", "),
          town: bookingAddresses.pickup.post_town,
          postcode: bookingAddresses.pickup.postcode,
        },
        dropoffAddress: {
          number: bookingAddresses.dropoff.line_1,
          street: [
            bookingAddresses.dropoff.line_2,
            bookingAddresses.pickup.line_3,
          ]
            .filter((val) => val && val.length > 0)
            .join(", "),
          town: bookingAddresses.dropoff.post_town,
          postcode: bookingAddresses.dropoff.postcode,
        },
      }


      Api.post("w/magic-lead-initial", paramsInit);

      return;
    }


    const selectedVanOption =  CUSTOM_VAN_CHOICES[bookingGeneral.vanSizeIndex];

    const params = {
      jobDescription: jobDescription,
      pickupAddress: {
        number: bookingAddresses.pickup.line_1,
        street: [bookingAddresses.pickup.line_2, bookingAddresses.pickup.line_3]
          .filter((val) => val && val.length > 0)
          .join(", "),
        town: bookingAddresses.pickup.post_town,
        postcode: bookingAddresses.pickup.postcode,
      },
      dropoffAddress: {
        number: bookingAddresses.dropoff.line_1,
        street: [
          bookingAddresses.dropoff.line_2,
          bookingAddresses.pickup.line_3,
        ]
          .filter((val) => val && val.length > 0)
          .join(", "),
        town: bookingAddresses.dropoff.post_town,
        postcode: bookingAddresses.dropoff.postcode,
      },
      pickup: bookingGeneral.arrival,
      //size: VANS[bookingGeneral.vanSizeIndex].apiName,
      liftingPowerIndex:
        selectedVanOption.possibleOptions[
          bookingPricesCustomChoices.vanSizes[selectedVanOption.vanSize]
            .selectedHelperIndex
        ].helperCount,
      liftingPower: bookingGeneral.helpLiftingIndex,
      rideAlong: bookingGeneral.rideAlong,
      stairs: bookingGeneral.noOfStairs,
      utmArray: getState().tracking.utmArray,
      requiredTime: bookingDistance.minimumTimeHours + bookingGeneral.extraTime,
      distance: parseFloat(bookingDistance.distance),
      duration: parseInt(bookingDistance.duration),
      pickupHasLift: bookingPricesCustomChoices.pickupWithLift,
      pickupStairs: bookingPricesCustomChoices.pickupStairs,
      dropoffHasLift: bookingPricesCustomChoices.dropoffWithLift,
      dropoffStairs: bookingPricesCustomChoices.dropoffStairs,
    };

    if (!Utils.isBlank(bookingGeneral.name)) params.name = bookingGeneral.name; // name of booking
    if (!Utils.isBlank(bookingGeneral.arrival))
      params.pickup = bookingGeneral.arrival;
    if (!Utils.isBlank(userDetails.firstName))
      params.firstName = userDetails.firstName;
    if (!Utils.isBlank(userDetails.lastName))
      params.lastName = userDetails.lastName;
    if (Utils.isValidEmail(userDetails.email)) params.email = userDetails.email;
    if (Utils.isValidMobile(userDetails.mobileNumber))
      params.phone = userDetails.mobileNumber;
    if (bookingGeneral.vanSizeIndex >= 0)
      params.size = VANS[bookingGeneral.vanSizeIndex].apiName;

    const additionalStops = getState().bookingAdditionalStops.stops;
    if (additionalStops.length > 0) {
      const formattedAdditionalStops = [];
      for (let i = 0; i < additionalStops.length; i++) {
        const formattedAddress = {
          number: additionalStops[i].line_1,
          street: [additionalStops[i].line_2, additionalStops[i].line_3]
            .filter((val) => val && val.length > 0)
            .join(", "),
          town: additionalStops[i].post_town,
          postcode: additionalStops[i].postcode,
        };
        formattedAdditionalStops.push(formattedAddress);
      }
      params.additionalStops = formattedAdditionalStops;
    }

    const collectionRange = getState().bookingGeneral.collectionRange;
    if (collectionRange) params.collectionRange = collectionRange;

    if (bookingGeneral.inventory?.length > 0)
      params.inventory = JSON.stringify(bookingGeneral.inventory);


    // calculation for initialTotal && extra charge value
    if (bookingGeneral.vanSizeIndex != null) {
      let bookingOption = bookingPricesCustomChoices.vanSizes[VANS[bookingGeneral.vanSizeIndex].apiName];
      let priceIndex = bookingOption.selectedHelperIndex;
      let bookingkey = Object.keys(bookingOption)[priceIndex];
      let bookingPrice = bookingOption[bookingkey];
      if (bookingPrice != null) {
        params.initialTotal = bookingPrice.estimate;
        params.extraChargeValue = bookingPrice.extraChargeValue;
      }
    }



    // Extra check if mobile number is sent straight away from mobile popup ...
    if (mobileNumber) params.phone = mobileNumber;

    Api.post("w/magic-lead", params);
    // .then(resp => console.log(resp))
    // .catch(err => console.log(err))
  };
}
