import Api from '../../../api/index';
import Utils from '../../../library/utils';

export const ADD_NEW_UTM_STRING = 'ADD_NEW_UTM_STRING';

export const ADD_USER_DETAILS_FROM_JUKEBOX = 'ADD_USER_DETAILS_FROM_JUKEBOX';
export const ADD_ADDRESSES_FROM_JUKEBOX = 'ADD_ADDRESSES_FROM_JUKEBOX';
export const SETUP_ADDRESSES_EMPTY_OBJECT_FOR_JUKEBOX = 'SETUP_ADDRESSES_EMPTY_OBJECT_FOR_JUKEBOX';
// export const EDIT_BOOKING_GENERAL_FROM_JUKEBOX = 'EDIT_BOOKING_GENERAL_FROM_JUKEBOX';

export function addNewUtmString(utmString) { // Saves the latest utm string to the array of UTMs
  return {
    type: ADD_NEW_UTM_STRING,
    payload: {
      utmString: utmString
    }
  }
}

export function linkUserWithEmailClick(email, utmString, timestamp) {
  return () => {
    const params = {
      email: email,
      utmString: utmString,
      timestamp: timestamp
    };

    Api.post('w/email-action', params)
      .then((resp) => {
        // console.log(resp)
      })
      .catch((err) => {
        // console.log(err)
      })
  }
}


export function setInfoFromJukebox(jbFirstName, jbLastName, jbEmail, jbPhone, jbPickup, jbDropoff) { // jbJobName
  return (dispatch) => {
    const userDetailsPayload = {};
    if (jbFirstName && !Utils.isBlank(jbFirstName)) {userDetailsPayload.firstName = jbFirstName}
    if (jbLastName && !Utils.isBlank(jbLastName)) {userDetailsPayload.lastName = jbLastName}
    if (jbEmail && !Utils.isBlank(jbEmail)) {userDetailsPayload.email = jbEmail}
    if (jbPhone && !Utils.isBlank(jbPhone)) {userDetailsPayload.mobileNumber = jbPhone}
    if (!Utils.isObjectEmpty(userDetailsPayload)) {
      dispatch({
        type: ADD_USER_DETAILS_FROM_JUKEBOX,
        payload: userDetailsPayload
      });
    }

    const addressesPayload = {};
    if (jbPickup && !Utils.isBlank(jbPickup)) {addressesPayload.pickup = jbPickup}
    if (jbDropoff && !Utils.isBlank(jbDropoff)) {addressesPayload.dropoff = jbDropoff}
    if (!Utils.isObjectEmpty(addressesPayload)) {
      dispatch({
        type: ADD_ADDRESSES_FROM_JUKEBOX,
        payload: addressesPayload
      });
    }

    // const bookingGeneralPayload = {};
    // if (jbJobName && !Utils.isBlank(jbJobName)) {bookingGeneralPayload.name = jbJobName}
    // if (!Utils.isObjectEmpty(bookingGeneralPayload)) {
    //   dispatch({
    //     type: EDIT_BOOKING_GENERAL_FROM_JUKEBOX,
    //     payload: bookingGeneralPayload
    //   });
    // }

  }
}
