import {
  calculateNewPrice,
  calculateCustomPrices,
} from "../../../actions/PriceActions";
import Api from "../../../api/index";
import { SET_PRICES } from "../../heroes/GetAQuoteHero/GetAQuoteHeroActions";

export const SET_VAN_SIZE = "SET_VAN_SIZE";
export const CHANGE_HELP_LIFTING_FOR_VAN_SIZE =
  "CHANGE_HELP_LIFTING_FOR_VAN_SIZE";

export function changeHelpLiftingForVanSize(vanSizeApiName, helpLiftingIndex) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_HELP_LIFTING_FOR_VAN_SIZE,
      payload: {
        helpLiftingIndex: helpLiftingIndex,
        vanSizeApiName: vanSizeApiName,
      },
    });

    // dispatch(calculateVanPrices(vanSizeApiName))
  };
}

export function setVanSize(vanSizeIndex) {
  return (dispatch) => {
    dispatch({
      type: SET_VAN_SIZE,
      payload: {
        vanSizeIndex: vanSizeIndex,
      },
    });

    dispatch(calculateCustomPrices());
  };
}

export function generateQuotesPerVanSize() {
  return (dispatch, getState) => {
    const pickupPostcode = getState().bookingAddresses.pickup.postcode.replace(
      /\s/g,
      ""
    );
    const dropoffPostcode = getState().bookingAddresses.dropoff.postcode.replace(
      /\s/g,
      ""
    );

    const params = {
      startPostcode: pickupPostcode,
      endPostcode: dropoffPostcode,
    };

    Api.post("pricing/grid", params).then((resp) => {
      dispatch({
        type: SET_PRICES,
        payload: {
          medium: resp.medium,
          long: resp.long,
          luton: resp.luton,
        },
      });
    });
  };
}
