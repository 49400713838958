import update from 'immutability-helper';
import { SET_DRIVER_FORM_ERROR, SET_DRIVER_FORM_SUCCESS } from '../containers/drivers/ApplicationForm.actions';

const initialState = {
  error: '',
  success: false
};

function driverForm(state = initialState, action) {
  switch (action.type) {
    case SET_DRIVER_FORM_ERROR:
      return update(state, {
        error: {$set: action.error}
      });
    case SET_DRIVER_FORM_SUCCESS:
      return update(state, {
        success: {$set: action.success}
      });
    default:
      return state
  }
}

export default driverForm;
