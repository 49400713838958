import update from 'immutability-helper';
import * as types from '../containers/heroes/GetAQuoteHero/GetAQuoteHeroActions';
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  pickupPostcode: null,
  dropoffPostcode: null,
  errorMessage: null,
  showButtonLoader: false,
  showQuotes: false,
  estimates: {
    medium: null,
    long: null,
    luton: null
  }
};

function getAQuote(state = initialState, action) {
  switch (action.type) {
    case types.HIDE_POSTCODE_ERROR:
      return update(state, {
        errorMessage: {$set: null}
      });
    case types.SHOW_POSTCODE_ERROR:
      return update(state, {
        errorMessage: {$set: action.payload.errorMessage}
      });
    case types.VALIDATING_POSTCODES:
      return update(state, {
        showButtonLoader: {$set: true}
      });
    case types.VALIDATING_POSTCODES_RESOLVED:
      return update(state, {
        showButtonLoader: {$set: false}
      });
    case types.SHOW_QUOTES:
      return update(state, {
        showQuotes: {$set: true},
        pickupPostcode: {$set: action.payload.pickupPostcode},
        dropoffPostcode: {$set: action.payload.dropoffPostcode}
      });
    case types.HIDE_AND_RESET_QUOTES:
      return update(initialState, {
        pickupPostcode: {$set: state.pickupPostcode},
        dropoffPostcode: {$set: state.dropoffPostcode}
      });
    case types.SET_PRICES:
      return update(state, {
        estimates: {
          medium: {$set: action.payload.medium},
          long: {$set: action.payload.long},
          luton: {$set: action.payload.luton},
        }
      });
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default getAQuote;
