import Api from "../api/index";
import { VANS } from "../library/vans";
import { LIFTING_CHOICES_API } from "../library/lifting";
import Utils from "../library/utils";
import { setNumberOfStairs } from "../containers/bookingStep2/Customise/CustomiseActions";

export const CALCULATE_PRICE = "CALCULATE_PRICE";
export const CALCULATE_PRICE_FAILED = "CALCULATE_PRICE_FAILED";
export const CALCULATE_PRICE_SUCCESS = "CALCULATE_PRICE_SUCCESS";
export const CALCULATE_PRICES_PER_VAN_SIZE = "CALCULATE_PRICES_PER_VAN_SIZE";
export const CALCULATE_PRICE_FOR_VAN_SUCCESS =
  "CALCULATE_PRICE_FOR_VAN_SUCCESS";
export const SET_PRICE_FOR_CUSTOM_OPTION = "SET_PRICE_FOR_CUSTOM_OPTION";
export const SET_SELECTED_HELPER_INDEX = "SET_SELECTED_HELPER_INDEX";
export const SET_LIFT_CHANGE = "SET_LIFT_CHANGE";

const moment = require("moment");

export function calculateCustomPrices() {
  return (dispatch, getState) => {
    const bookingGeneral = getState().bookingGeneral;
    const bookingDistance = getState().bookingDistance;
    const bookingAddresses = getState().bookingAddresses;
    const additionalStops = getState().bookingAdditionalStops.stops; // []
    const bookingPricesCustomChoices = getState().bookingPricesCustomChoices;

    const params = {
      stairs: bookingGeneral.noOfStairs.toString(),
      requiredTime: bookingDistance.minimumTimeHours + bookingGeneral.extraTime,
      distanceInMiles: bookingDistance.distance,
      durationInMinutes: bookingDistance.duration,
      collectionRange: bookingGeneral.collectionRange,
      // pickup: pickup,

      // new:
      route: {
        pickupLocation: {
          ...bookingAddresses.pickup,
          ...{
            hasLift: bookingPricesCustomChoices.pickupWithLift,
            stairs: bookingPricesCustomChoices.pickupStairs,
          },
        },
        dropoffLocation: {
          ...bookingAddresses.dropoff,
          ...{
            hasLift: bookingPricesCustomChoices.dropoffWithLift,
            stairs: bookingPricesCustomChoices.dropoffStairs,
          },
        },
      },
    };

    let arrival = `${moment().add(4, "d").format("YYYY-MM-DD")} 17:00`;

    if (bookingGeneral.arrival) {
      arrival = bookingGeneral.arrival;
    }
    params.pickup = arrival;

    if (additionalStops[0]) params.route.additionalStop0 = additionalStops[0];
    if (additionalStops[1]) params.route.additionalStop0 = additionalStops[1];
    if (additionalStops[2]) params.route.additionalStop0 = additionalStops[2];
    if (additionalStops[3]) params.route.additionalStop0 = additionalStops[3];

    // 0: Small Van no helper
    const paramsForSmallHelp0 = Object.assign({}, params, {
      size: "Small",
      liftingPower: LIFTING_CHOICES_API[0].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForSmallHelp0).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Small",
          liftingPower: "estimateHelp0",
        },
      });
    });

    // 1: Small Van 1 helper
    const paramsForSmallHelp1 = Object.assign({}, params, {
      size: "Small",
      liftingPower: LIFTING_CHOICES_API[1].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForSmallHelp1).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Small",
          liftingPower: "estimateHelp1",
        },
      });
    });

    // 1-A: Medium Van with Driver Help
    const paramsForMediumHelp0 = Object.assign({}, params, {
      size: "Medium",
      liftingPower: LIFTING_CHOICES_API[0].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForMediumHelp0).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Medium",
          liftingPower: "estimateHelp0",
        },
      });
    });

    // 1-B: Medium Van with Driver Help
    const paramsForMediumHelp1 = Object.assign({}, params, {
      size: "Medium",
      liftingPower: LIFTING_CHOICES_API[1].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForMediumHelp1).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Medium",
          liftingPower: "estimateHelp1",
        },
      });
    });

    // 1-C: Medium Van with Driver Help
    const paramsForMediumHelp2 = Object.assign({}, params, {
      size: "Medium",
      liftingPower: LIFTING_CHOICES_API[2].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForMediumHelp2).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Medium",
          liftingPower: "estimateHelp2",
        },
      });
    });

    // 1-D: Medium Van with Driver Help
    const paramsForMediumHelp3 = Object.assign({}, params, {
      size: "Medium",
      liftingPower: LIFTING_CHOICES_API[3].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForMediumHelp3).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Medium",
          liftingPower: "estimateHelp3",
        },
      });
    });

    // 2-A: Large Van with Driver Help
    const paramsForLargeHelp0 = Object.assign({}, params, {
      size: "Long",
      liftingPower: LIFTING_CHOICES_API[0].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLargeHelp0).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Long",
          liftingPower: "estimateHelp0",
        },
      });
    });

    // 2-B: Large Van with Driver Help
    const paramsForLargeHelp1 = Object.assign({}, params, {
      size: "Long",
      liftingPower: LIFTING_CHOICES_API[1].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLargeHelp1).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Long",
          liftingPower: "estimateHelp1",
        },
      });
    });

    // 2-C: Large Van with Driver Help
    const paramsForLargeHelp2 = Object.assign({}, params, {
      size: "Long",
      liftingPower: LIFTING_CHOICES_API[2].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLargeHelp2).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Long",
          liftingPower: "estimateHelp2",
        },
      });
    });

    // 2-D: Large Van with Driver Help
    const paramsForLargeHelp3 = Object.assign({}, params, {
      size: "Long",
      liftingPower: LIFTING_CHOICES_API[3].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLargeHelp3).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Long",
          liftingPower: "estimateHelp3",
        },
      });
    });

    // 3-A: Luton Van with Driver Help
    const paramsForLutonHelp0 = Object.assign({}, params, {
      size: "Luton",
      liftingPower: LIFTING_CHOICES_API[0].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLutonHelp0).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Luton",
          liftingPower: "estimateHelp0",
        },
      });
    });

    // 3-B: Luton Van with Driver Help
    const paramsForLutonHelp1 = Object.assign({}, params, {
      size: "Luton",
      liftingPower: LIFTING_CHOICES_API[1].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLutonHelp1).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Luton",
          liftingPower: "estimateHelp1",
        },
      });
    });

    // 3-C: Luton Van with Driver Help
    const paramsForLutonHelp2 = Object.assign({}, params, {
      size: "Luton",
      liftingPower: LIFTING_CHOICES_API[2].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLutonHelp2).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Luton",
          liftingPower: "estimateHelp2",
        },
      });
    });

    // 3-D: Luton Van with Driver Help
    const paramsForLutonHelp3 = Object.assign({}, params, {
      size: "Luton",
      liftingPower: LIFTING_CHOICES_API[3].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForLutonHelp3).then((resp) => {
      dispatch({
        type: SET_PRICE_FOR_CUSTOM_OPTION,
        payload: {
          // estimate: estimateValue.estimate, // estimate: resp,
          estimate: resp,
          size: "Luton",
          liftingPower: "estimateHelp3",
        },
      });
    });

    // 4-A: Two Luton Van with Driver Help
    const paramsForTwoLutonHelp0 = Object.assign({}, params, {
      size: "Luton_2",
      liftingPower: LIFTING_CHOICES_API[0].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForTwoLutonHelp0).then(
      (resp) => {
        dispatch({
          type: SET_PRICE_FOR_CUSTOM_OPTION,
          payload: {
            // estimate: estimateValue.estimate, // estimate: resp,
            estimate: resp,
            size: "Luton_2",
            liftingPower: "estimateHelp0",
          },
        });
      }
    );

    // 4-B: Two Luton Van with Driver Help
    const paramsForTwoLutonHelp2 = Object.assign({}, params, {
      size: "Luton_2",
      liftingPower: LIFTING_CHOICES_API[2].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForTwoLutonHelp2).then(
      (resp) => {
        dispatch({
          type: SET_PRICE_FOR_CUSTOM_OPTION,
          payload: {
            // estimate: estimateValue.estimate, // estimate: resp,
            estimate: resp,
            size: "Luton_2",
            liftingPower: "estimateHelp2",
          },
        });
      }
    );

    // 5-A: Two Luton Van with Driver Help
    const paramsForThreeLutonHelp0 = Object.assign({}, params, {
      size: "Luton_3",
      liftingPower: LIFTING_CHOICES_API[0].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForThreeLutonHelp0).then(
      (resp) => {
        dispatch({
          type: SET_PRICE_FOR_CUSTOM_OPTION,
          payload: {
            // estimate: estimateValue.estimate, // estimate: resp,
            estimate: resp,
            size: "Luton_3",
            liftingPower: "estimateHelp0",
          },
        });
      }
    );

    // 5-B: Two Luton Van with Driver Help
    const paramsForTwoLutonHelp3 = Object.assign({}, params, {
      size: "Luton_3",
      liftingPower: LIFTING_CHOICES_API[3].helperCount,
    });
    Api.post("w/pricing/smart-estimate", paramsForTwoLutonHelp3).then(
      (resp) => {
        dispatch({
          type: SET_PRICE_FOR_CUSTOM_OPTION,
          payload: {
            // estimate: estimateValue.estimate, // estimate: resp,
            estimate: resp,
            size: "Luton_3",
            liftingPower: "estimateHelp3",
          },
        });
      }
    );
  };
}

export function setSelectedHelperIndex(vanSize, selectedIndex) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_HELPER_INDEX,
      payload: {
        vanSize,
        selectedIndex,
      },
    });
  };
}

export function calculateNewPrice() {
  return (dispatch, getState) => {
    dispatch({
      type: CALCULATE_PRICE,
    });

    const bookingGeneral = getState().bookingGeneral;
    const bookingDistance = getState().bookingDistance;
    const bookingAddresses = getState().bookingAddresses;
    const userDetails = getState().userDetails;

    if (bookingGeneral.vanSizeIndex === null) return;

    const params = {
      size: VANS[bookingGeneral.vanSizeIndex].apiName,
      liftingPower:
        LIFTING_CHOICES_API[bookingGeneral.helpLiftingIndex].helperCount,
      stairs: bookingGeneral.noOfStairs.toString(),
      requiredTime: bookingDistance.minimumTimeHours + bookingGeneral.extraTime,
      distanceInMiles: bookingDistance.distance,
      durationInMinutes: bookingDistance.duration,
      collectionRange: bookingGeneral.collectionRange,

      // new:
      route: {
        pickupLocation: bookingAddresses.pickup,
        dropoffLocation: bookingAddresses.dropoff,
      },

      // discountRule: {
      //   type: 'string',
      //   example: '-10%',
      //   description: 'Optional rule to apply a discount, expressed as a string'
      // },
      // todo: add somewhere to apply a discount ...
    };

    const additionalPostcodes = [];
    for (const stop of getState().bookingAdditionalStops.stops) {
      if (!Utils.isBlank(stop.postcode))
        additionalPostcodes.push(stop.postcode);
    }
    if (additionalPostcodes.length > 0)
      params.route.additionalPostcodes = additionalPostcodes;

    if (!Utils.isBlank(userDetails.mobileNumber)) {
      params.phone = userDetails.mobileNumber;
    }

    if (!Utils.isBlank(userDetails.email)) {
      params.email = userDetails.email;
    }

    let arrival = `${moment().add(4, "d").format("YYYY-MM-DD")} 17:00`;
    console.log({ arrival1: arrival });

    if (bookingGeneral.arrival) {
      console.log({ arrival2: arrival });
      arrival = bookingGeneral.arrival;
    }
    console.log({ arrival3: arrival });
    params.pickup = arrival;

    Api.post("w/pricing/smart-estimate", params).then((resp) => {
      if (resp.err) {
        dispatch({
          type: CALCULATE_PRICE_FAILED,
          payload: {
            errorMessage: resp.err,
          },
        });

        return;
      }

      dispatch({
        type: CALCULATE_PRICE_SUCCESS,
        payload: {
          resp,
        },
      });
    });
  };
}

export function setLiftChange(pickupWithLift, dropoffWithLift) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LIFT_CHANGE,
      payload: {
        pickupWithLift,
        dropoffWithLift,
      },
    });

    const {
      dropoffStairs,
      pickupStairs,
    } = getState().bookingPricesCustomChoices;

    let stairsCount = 0;

    if (!pickupWithLift) {
      stairsCount = pickupStairs + stairsCount;
    }

    if (!dropoffWithLift) {
      stairsCount = dropoffStairs + stairsCount;
    }

    dispatch(setNumberOfStairs(stairsCount));
  };
}
