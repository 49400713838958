import update from 'immutability-helper';
import {
  LEAVE_USER_INFO_FORM,
  SUBMIT_USER_INFO,
  SUBMIT_USER_INFO_SUCCESS
} from '../containers/heroes/RemindMeLater/RemindMeLaterActions';
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  name: '',
  pickupDate: null, // pickup time
  pickupTime: null,
  pickupDateAndTime: null,
  phone: '',
  email: '',

  // submitting user info
  submitting: false,
  submittedSuccess: false
};

function userInfo(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_USER_INFO:
      return update(state, {
        submitting: {$set: true},
        name: {$set: action.payload.name},
        pickupDate: {$set: action.payload.pickupDate},
        pickupTime: {$set: action.payload.pickupTime},
        pickupDateAndTime: {$set: action.payload.pickupDateAndTime},
        phone: {$set: action.payload.phone},
        email: {$set: action.payload.email},
      });
    case SUBMIT_USER_INFO_SUCCESS:
      return update(state, {
        submitting: {$set: false},
        submittedSuccess: {$set: true}
      });
    case LEAVE_USER_INFO_FORM:
      return update(state, {
        submitting: {$set: false},
        submittedSuccess: {$set: false}
      });
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default userInfo;
