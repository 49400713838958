import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/DoneRounded';
import React from 'react';
import COLORS from '../../../styles/colors';
import { Link } from 'gatsby';

export const CookieConsent = (props) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={props.open}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">
      We use cookies to provide you with a better experience. Carry on browsing if you're happy with this or find out how to manage cookies <Link
        style={{ color: COLORS.kgGreen, textDecoration: 'underline' }} to={'/cookies-policy'}>here</Link>
    </span>}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        // className={classes.close}
        onClick={props.acknowledgeCookies}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />

);
