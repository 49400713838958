export const RESET_BOOKING = 'RESET_BOOKING';
export const WIPE_DATA = 'WIPE_DATA';

export function resetBooking() {
  return {
    type: RESET_BOOKING
  }
}

export function wipeData() {
  return dispatch => {
    dispatch(resetBooking());
    dispatch({type: WIPE_DATA})
  }
}