export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_MOBILE_NUMBER = 'SET_MOBILE_NUMBER';

export function setFirstName(firstName) {
  return {
    type: SET_FIRST_NAME,
    payload: {
      firstName: firstName
    }
  }
}

export function setLastName(lastName) {
  return {
    type: SET_LAST_NAME,
    payload: {
      lastName: lastName
    }
  }
}

export function setMobileNumber(mobileNumber) {
  return {
    type: SET_MOBILE_NUMBER,
    payload: {
      mobileNumber: mobileNumber
    }
  }
}

export function setEmail(email) {
  return {
    type: SET_EMAIL,
    payload: {
      email: email
    }
  }
}