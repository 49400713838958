import Api from '../../../api';
import ReactGA from 'react-ga';
import Utils from '../../../library/utils';

export const PAYING_FOR_RESERVATION = 'PAYING_FOR_RESERVATION';
export const RESERVATION_PAYMENT_FAILED = 'RESERVATION_PAYMENT_FAILED';
export const RESERVATION_PAYMENT_SUCCESSFUL = 'RESERVATION_PAYMENT_SUCCESSFUL';

export function payReservation(token) {
  return (dispatch, getState) => {
    dispatch({
      type: PAYING_FOR_RESERVATION
    });

    ReactGA.event({
      category: 'Reservation',
      action: 'Stripe - successfully created token',
      label: 'Token generated'
    });

    const params = {
      amount: 2000,
      token: token.id,
      reservationId: getState().reservation.reservationId,
      oneUseToken: Utils.generateGuid()
    };

    Api.post('w/reservation/payment', params)
      .then((resp) => {
        if (resp.err) {

          ReactGA.event({
            category: 'Reservation',
            action: 'Payment failed',
            label: resp.err
          });

          dispatch({
            type: RESERVATION_PAYMENT_FAILED,
            payload: {
              errorMessage: resp.err
            }
          });

          return;
        }

        dispatch({
          type: RESERVATION_PAYMENT_SUCCESSFUL
        });

        ReactGA.event({
          category: 'Reservation',
          action: 'Payment successful',
          label: 'Payment successful'
        });

      })
      .catch((err) => {
        ReactGA.event({
          category: 'Reservation',
          action: 'Payment failed',
          label: err // catch err
        });
      })
  }
}
