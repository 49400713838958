/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { persistor } from './src/Store';
import { PersistGate } from 'redux-persist/integration/react';
import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import CookieConsentContainer from './src/containers/bootstrap/CookieConsent/CookieConsentContainer';
import MuiTheme from './src/styles/theme';
import './src/index.css';
import { Provider } from 'react-redux'
import { store } from './src/Store';
import SecurityContainer from './src/containers/bootstrap/Security/SecurityContainer'

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={MuiTheme}>
        {element}
        <CookieConsentContainer/>
        <SecurityContainer/>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
};

