import _ from "lodash";
import update from "immutability-helper";
import { RESET_BOOKING } from "../containers/bookingStep5/BookingSuccess/BookingSuccessActions";
import { SAVE_STAIRS_INDEX_FOR_PICKUP_AND_DROPOFF } from "../containers/bookingStep0/RoutePlanner/RoutePlannerActions";
import {
  SET_PRICE_FOR_CUSTOM_OPTION,
  SET_SELECTED_HELPER_INDEX,
  SET_LIFT_CHANGE,
} from "../actions/PriceActions";

const initialState = {
  vanSizes: {
    Small: {
      estimateHelp0: null,
      estimateHelp1: null,
      selectedHelperIndex: 0,
    },
    Medium: {
      estimateHelp0: null,
      estimateHelp1: null,
      estimateHelp2: null,
      estimateHelp3: null,
      selectedHelperIndex: 0,
    },
    Long: {
      estimateHelp0: null,
      estimateHelp1: null,
      estimateHelp2: null,
      estimateHelp3: null,
      selectedHelperIndex: 0,
    },
    Luton: {
      estimateHelp0: null,
      estimateHelp1: null,
      estimateHelp2: null,
      estimateHelp3: null,
      selectedHelperIndex: 0,
    },
    Luton_2: {
      estimateHelp0: null,
      estimateHelp2: null,
      selectedHelperIndex: 0,
    },
    Luton_3: {
      estimateHelp0: null,
      estimateHelp3: null,
      selectedHelperIndex: 0,
    },
  },

  // Stairs:
  pickupStairs: 0,
  dropoffStairs: 0,
  pickupWithLift: false,
  dropoffWithLift: false,
};

function bookingPricesCustomChoices(state = initialState, action) {
  switch (action.type) {
    case SET_PRICE_FOR_CUSTOM_OPTION:
      return update(state, {
        vanSizes: {
          [action.payload.size]: {
            [action.payload.liftingPower]: {
              $set: action.payload.estimate,
            },
          },
        },
      });
    case SAVE_STAIRS_INDEX_FOR_PICKUP_AND_DROPOFF:
      return update(state, {
        pickupStairs: { $set: action.payload.pickupStairs },
        dropoffStairs: { $set: action.payload.dropoffStairs },
      });

    case SET_LIFT_CHANGE:
      return update(state, {
        pickupWithLift: { $set: action.payload.pickupWithLift },
        dropoffWithLift: { $set: action.payload.dropoffWithLift },
      });

    case SET_SELECTED_HELPER_INDEX: {
      const vanSizesClone = _.cloneDeep(state.vanSizes);
      vanSizesClone[action.payload.vanSize].selectedHelperIndex =
        action.payload.selectedIndex;
      console.log({ vanSizesClone, vanSizes: state.vanSizes });
      return update(state, {
        vanSizes: { $set: vanSizesClone },
      });
    }

    case RESET_BOOKING:
      return initialState;
    default:
      return state;
  }
}

export default bookingPricesCustomChoices;
