import update from "immutability-helper";
import {
  CLEAR_POSTCODE_ERROR,
  POPULATE_ADDRESS,
  POSTCODE_INVALID,
  SELECT_ADDRESS_SUGGESTION,
  SUGGESTIONS_FOUND,
} from "../containers/bookingStep0/RoutePlanner/RoutePlannerActions";
import { SAVE_ADDRESS_MANUALLY } from "../containers/modals/Address/AddressModalActions";
import { RESET_BOOKING } from "../containers/bookingStep5/BookingSuccess/BookingSuccessActions";
import { CLEAR_ADDRESS } from "../actions/AddressActions";
import { POPULATE_ADDRESSES_FROM_EASY_BOOKING } from "../containers/easyBooking/EasyBooking/EasyBookingActions";

const emptyAddress = {
  fullAddress: "",
  line_1: "",
  line_2: "",
  line_3: "",
  post_town: "",
  postcode: "",
  selectedFromSuggestion: false,
  postcodeValid: false,
  postcodeError: null,
};

const initialState = {
  // RoutePlanner:
  pickup: Object.assign({}, emptyAddress),
  dropoff: Object.assign({}, emptyAddress),

  // Suggestions:
  suggestions: {
    pickup: [],
    dropoff: [],
    additionalStop0: [],
    additionalStop1: [],
    additionalStop2: [],
    additionalStop3: [],
  },
};

function bookingAddresses(state = initialState, action) {
  switch (action.type) {
    case POPULATE_ADDRESSES_FROM_EASY_BOOKING:
      return update(state, {
        pickup: { $set: action.payload.pickup },
        dropoff: { $set: action.payload.dropoff },
      });
    case CLEAR_ADDRESS:
      return update(state, {
        [action.payload.whichAddress]: { $set: emptyAddress },
      });
    case SUGGESTIONS_FOUND:
      return update(state, {
        suggestions: {
          [action.payload.whichAddress]: { $set: action.payload.suggestions },
        },
      });
    case SELECT_ADDRESS_SUGGESTION:
      return update(state, {
        [action.payload.whichAddress]: {
          selectedFromSuggestion: { $set: true },
        },
        suggestions: {
          [action.payload.whichAddress]: {
            suggestions: { $set: [] },
          },
        },
      });
    case SAVE_ADDRESS_MANUALLY:
      return update(state, {
        [action.payload.whichAddress]: {
          fullAddress: { $set: action.payload.fullAddress },
          line_1: { $set: action.payload.line_1 },
          line_2: { $set: action.payload.line_2 },
          line_3: { $set: action.payload.line_3 },
          post_town: { $set: action.payload.post_town },
          postcode: { $set: action.payload.postcode },
        },
      });
    case POPULATE_ADDRESS:
      return update(state, {
        [action.payload.whichAddress]: {
          fullAddress: { $set: action.payload.fullAddress },
          line_1: { $set: action.payload.line_1 },
          line_2: { $set: action.payload.line_2 },
          line_3: { $set: action.payload.line_3 },
          post_town: { $set: action.payload.post_town },
          postcode: { $set: action.payload.postcode },
          longitude: { $set: action.payload.longitude },
          latitude: { $set: action.payload.latitude },
        },
      });
    case POSTCODE_INVALID:
      return update(state, {
        [action.payload.whichAddress]: {
          postcodeError: { $set: action.payload.errorMessage },
        },
      });
    case CLEAR_POSTCODE_ERROR:
      return update(state, {
        [action.payload.whichAddress]: {
          postcodeError: { $set: null },
        },
      });
    case RESET_BOOKING:
      return initialState; // also removes one use token
    default:
      return state;
  }
}

export default bookingAddresses;
