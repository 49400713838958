// Set prices when quotes have been returned
import Api from '../../api/index';

export const SET_DRIVER_FORM_SUCCESS = 'SET_DRIVER_FORM_SUCCESS';
export const SET_DRIVER_FORM_ERROR = 'SET_DRIVER_FORM_ERROR';

export function submitDriverRequest(applicationForm) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DRIVER_FORM_ERROR,
      error: ''
    });

    // TODO all the validation and trimming etc.
    if (!applicationForm.firstName) {
      return bailWithError('You must provide a first name');
    }

    if (!applicationForm.lastName) {
      return bailWithError('You must provide a last name');
    }

    if (!applicationForm.email) {
      return bailWithError('You must provide a valid email');
    }

    // TODO - check email format

    if (!applicationForm.phone) {
      return bailWithError('You must provide a valid UK mobile number');
    }

    // TODO check mobile format

    if (!applicationForm.makeModel) {
      return bailWithError('You must provide the make and model of your van');
    }

    if (!applicationForm.city) {
      return bailWithError('You must provide the name of the place you live');
    }

    if (!applicationForm.postcode) {
      return bailWithError('You must provide your home postcode');
    }

    const postcodeValidateJson = await Api.post('w/postcode/validate', { postcode: applicationForm.postcode })
    if (!postcodeValidateJson.isValid) {
      return bailWithError('Home postcode is not valid');
    }

    if (!applicationForm.registration) {
      return bailWithError('You must provide your vehicle registration');
    }

    if (!applicationForm.nationalinsurancenum) {
      return bailWithError('You must provide national insurance number');
    }

    if (!applicationForm.drivlicensenum) {
      return bailWithError('You must provide driving license number');
    }

    // if (!applicationForm.dimW) {
    //   return bailWithError('You must provide your cargo area width');
    // }

    // if (!applicationForm.tons) {
    //   return bailWithError('You must provide your weight capacity');
    // }

    if (!applicationForm.vanSize) {
      return bailWithError('You must provide van size');
    }


    Api.post('w/driver/apply', applicationForm)
      .then(resp => {
        if (resp.err) {
          return dispatch({
            type: SET_DRIVER_FORM_ERROR,
            error: resp.err
          });
        }
        if (resp.success) {
          return dispatch({
            type: SET_DRIVER_FORM_SUCCESS,
            success: true
          });
        }
      })
      .catch(err => {
        return dispatch({
          type: SET_DRIVER_FORM_ERROR,
          error: err.message || err
        });
      });

    function bailWithError(err) {
      return dispatch({
        type: SET_DRIVER_FORM_ERROR,
        error: err
      });
    }
  }
}