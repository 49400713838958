export const SET_PICKUP_DATE_AND_TIME = 'SET_PICKUP_DATE_AND_TIME';
const moment = require('moment');

export function setPickupDateAndTime(time) {
  const apiFriendlyDateAndTime = time.replace('T', ' ');
  const formattedTime = moment(apiFriendlyDateAndTime).format('YYYY-MM-DD HH:mm');
  // console.log(formattedTime);
  return {
    type: SET_PICKUP_DATE_AND_TIME,
    payload: {
      pickupDateAndTime: formattedTime
    }
  }
}
