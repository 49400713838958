import { chooseLocation } from "../containers/bookingStep0/RoutePlanner/RoutePlannerActions";
import { estimateNewDistance } from "./DistanceActions";
import Api from "../api";
import { setCurrentModal } from "../containers/modals/Modal/ModalActions";

export const ADD_ADDITIONAL_STOP = "ADD_ADDITIONAL_STOP";
export const REMOVE_ADDITIONAL_STOP = "REMOVE_ADDITIONAL_STOP";
export const SELECT_ADDITIONAL_STOP_SUGGESTION =
  "SELECT_ADDITIONAL_STOP_SUGGESTION";
export const CLEAR_ADDITIONAL_STOP_POSTCODE_ERROR =
  "CLEAR_ADDITIONAL_STOP_POSTCODE_ERROR";
export const ADDITIONAL_STOP_POSTCODE_INVALID =
  "ADDITIONAL_STOP_POSTCODE_INVALID";
export const ADDITIONAL_STOP_POSTCODE_IS_VALID =
  "ADDITIONAL_STOP_POSTCODE_IS_VALID";

export const REMOVE_ALL_ADDITIONAL_STOPS = "REMOVE_ALL_ADDITIONAL_STOPS";

export function removeAllAdditionalStops() {
  return {
    type: REMOVE_ALL_ADDITIONAL_STOPS,
  };
}

export function addAdditionalStop() {
  return {
    type: ADD_ADDITIONAL_STOP,
  };
}

export function removeAdditionalStop(index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ADDITIONAL_STOP,
      payload: index,
    });

    dispatch(estimateNewDistance());
  };
}

export function selectAdditionalStopAddress(stop, hit, addAdditionalStopIndex) {
  return (dispatch) => {
    dispatch({
      type: SELECT_ADDITIONAL_STOP_SUGGESTION,
      payload: {
        whichAddress: stop,
      },
    });

    dispatch(chooseLocation(stop, hit, addAdditionalStopIndex));
  };
}

export function validateAdditionalStopPostcode(stopIndex, postcode) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ADDITIONAL_STOP_POSTCODE_ERROR,
      payload: {
        whichAddress: stopIndex,
      },
    });

    return Api.post("w/postcode/validate", { postcode }).then((resp) => {
      if (resp.err) {
        return;
      }

      if (resp.isValid) {
        dispatch({
          type: ADDITIONAL_STOP_POSTCODE_IS_VALID,
          payload: { stopIndex: stopIndex },
        });

        // Valid change of address - estimate new distance:
        dispatch(estimateNewDistance());
      } else {
        dispatch({
          type: ADDITIONAL_STOP_POSTCODE_INVALID,
          payload: {
            stopIndex: stopIndex,
            errorMessage: "Please enter a valid postcode",
          },
        });

        dispatch(
          setCurrentModal(`EDIT_ADDITIONAL_STOP_${stopIndex}_ADDRESS_MODAL`)
        );
      }
    });
  };
}
