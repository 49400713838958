import Utils from '../../../library/utils';

export const SET_JOB_NAME_FROM_HOMEPAGE = 'SET_JOB_NAME_FROM_HOMEPAGE';
export const SAVE_UNFINISHED_PICKUP_QUERY = 'SAVE_UNFINISHED_PICKUP_QUERY';
export const SAVE_UNFINISHED_DROPOFF_QUERY = 'SAVE_UNFINISHED_DROPOFF_QUERY';

export function setJobNameFromHomePage(jobName) {
  return {
    type: SET_JOB_NAME_FROM_HOMEPAGE,
    payload: {
      jobName: jobName
    }
  }
}

export function saveUnfinishedPickupQuery(pickupQuery) {
  return dispatch => {
    if (!Utils.isBlank(pickupQuery)) {
      dispatch({
        type: SAVE_UNFINISHED_PICKUP_QUERY,
        payload: pickupQuery
      })
    }
  };
}

export function saveUnfinishedDropoffQuery(dropoffQuery) {
  return dispatch => {
    if (!Utils.isBlank(dropoffQuery)) {
      dispatch({
        type: SAVE_UNFINISHED_DROPOFF_QUERY,
        payload: dropoffQuery
      })
    }
  };
}