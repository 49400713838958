import { SET_PICKUP_DATE_AND_TIME } from "../containers/bookingStep3/DatePickerAndCollection/DatePickerAndCollectionActions";
import { estimateNewDistance } from "./DistanceActions";

export const SET_RIDE_ALONG_PASSENGERS = "SET_RIDE_ALONG_PASSENGERS";
export const SET_COLLECTION_RANGE = "SET_COLLECTION_RANGE";

const moment = require("moment");

export function setRideAlongPassengers(noOfPassengers) {
  return {
    type: SET_RIDE_ALONG_PASSENGERS,
    payload: noOfPassengers,
  };
}

export function setCollectionRange(start, end, todayDate) {
  const difference = end - start;

  return (dispatch, getState) => {
    dispatch({
      type: SET_COLLECTION_RANGE,
      payload: {
        collectionRange: difference,
        collectionStart: start,
        collectionEnd: end,
      },
    });

    const pickupDateAndTime = getState().bookingGeneral.arrival;
    let pickupDate = moment().add(1, "d").format("YYYY-MM-DD");
    if (pickupDateAndTime)
      pickupDate = pickupDateAndTime.substr(0, pickupDateAndTime.indexOf(" ")); // split before hours ...
    if (todayDate) pickupDate = todayDate;
    const formattedTime = `${pickupDate} ${
      start < 10 ? "0" : ""
    }${start.toString()}:00`;

    dispatch({
      type: SET_PICKUP_DATE_AND_TIME,
      payload: {
        pickupDateAndTime: formattedTime,
      },
    });

    dispatch(estimateNewDistance(true));
  };
}

export function setPickupDate(date) {
  return (dispatch, getState) => {
    let collectionStart = "08";
    if (getState().bookingGeneral.collectionStart)
      collectionStart =
        getState().bookingGeneral.collectionStart < 10
          ? `0${getState().bookingGeneral.collectionStart.toString()}`
          : getState().bookingGeneral.collectionStart.toString();

    const pickupDate = moment(date).format("YYYY-MM-DD");
    // console.log({ pickupDate });
    const formattedTime = `${pickupDate} ${collectionStart.toString()}:00`;
    // console.log({ formattedTime });
    dispatch({
      type: SET_PICKUP_DATE_AND_TIME,
      payload: {
        pickupDateAndTime: formattedTime,
      },
    });

    dispatch(estimateNewDistance(true));
  };
}
