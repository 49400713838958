import {
  RESET_YOUR_QUOTE_IS_VALID_FOR,
  SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME
} from '../components/booking/YourQuoteIsValidFor/YourQuoteIsValidForActions';
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = 1200;

function yourQuoteIsValidFor(state = initialState, action) {
  switch (action.type) {
    case RESET_YOUR_QUOTE_IS_VALID_FOR:
      return 1200;
    case SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME:
      return action.payload;
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default yourQuoteIsValidFor;
