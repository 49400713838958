export const SET_JOB_DESCRIPTION = 'SET_JOB_DESCRIPTION';

export function setJobInstructions(userInput) {
  return {
    type: SET_JOB_DESCRIPTION,
    payload: {
      userInput: userInput
    }
  }
}
