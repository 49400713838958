import { CLOSE_MODAL, SET_CURRENT_MODAL } from '../containers/modals/Modal/ModalActions';
const initialState = null;

function currentModal(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_MODAL:
      return action.payload; // payload is modalName ...
    case CLOSE_MODAL:
      return null;
    default:
      return state
  }
}

export default currentModal;
