import { isMobile } from "react-device-detect";
import _ from "lodash";
import { LINKS } from "./links";
import { store } from "../Store";
import { CUSTOM_VAN_CHOICES } from "./vans";

const PhoneNumber = require("awesome-phonenumber");

class Utils {
  static randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static getProbalisticNumber(day) {

    console.log('day: ', day);
    
    let monthursday = [5, 5, 5, 5, 5, 4, 3, 3, 3, 2];
    let friday = [1, 2];
    let saturday = [1, 1, 1, 1, 2];
    let sunday = [1, 1, 1, 1, 1, 2, 2, 2, 2, 3];

    let idx = 0;

    //if day is between monday to thursday
    if (day >= 1 && day <= 4) {
      idx = Math.floor(Math.random() * monthursday.length);
      return monthursday[idx]
    }
    //if day is friday
    if (day === 5) {
      idx = Math.floor(Math.random() * friday.length);
      return friday[idx]
    }
    //if day is saturday
    if (day === 6) {
      idx = Math.floor(Math.random() * saturday.length);
      return saturday[idx]
    }

    //if day is sunday
    if (day === 0) {
      idx = Math.floor(Math.random() * sunday.length);
      return sunday[idx]
    }


    idx = Math.floor(Math.random() * monthursday.length);
    return monthursday[idx]



  }

  static getVanSizeIndexFromSize(size) {
    const lcSize = size.toLowerCase();
    if (lcSize === "medium") return 0;
    if (lcSize === "long" || lcSize === "large") return 1;
    if (lcSize === "luton" || lcSize === "xl") return 2;
    return null;
  }
  static apiAddressToMyFormat(addressObj) {
    return {
      fullAddress: `${addressObj.number} ${addressObj.street}, ${addressObj.town}, ${addressObj.postcode}`,
      line_1: `${addressObj.number} ${addressObj.street}`,
      line_2: "",
      line_3: "",
      post_town: addressObj.town,
      postcode: addressObj.postcode,
      selectedFromSuggestion: false,
      postcodeValid: true,
      postcodeError: null,
    };
  }

  static trimObject(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      }
    });
    return obj;
  }

  static isObjectEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  static objectDifference(objA, objB) {
    let result = {};
    Object.keys(objA).forEach((key) => {
      if (objA[key] !== objB[key]) {
        result[key] = objA[key];
      }
    });
    return result;
  }

  static ensureCoordinatesAreInUK(coords) {
    var result = coords;
    if (
      coords.latitude > 59.5 ||
      coords.latitude < 49.7 ||
      coords.longitude < -11.5 ||
      coords.longitude > 1.85
    ) {
      result.latitude = 51.517579;
      result.longitude = -0.118602;
    }
    return result;
  }

  static renderIf(condition, content) {
    if (condition) {
      return content;
    } else {
      return null;
    }
  }

  static zeroPadFront(input, desiredLength) {
    let result = input.toString();
    if (result.length < desiredLength) {
      var zerosToAdd = desiredLength - result.length;
      for (let i = 0; i < zerosToAdd; i++) {
        result = "0" + result;
      }
    }
    return result;
  }

  static getPoundsAndPennies(amount) {
    if (!amount) return "N/A";
    return "£" + this.getPounds(amount) + "." + this.getPennies(amount);
  }

  static getPounds(amount) {
    if (amount === 0) {
      return "0";
    }
    let str = amount.toString();
    return str.toString().substring(0, str.length - 2);
  }

  static getPennies(amount) {
    if (amount === 0) {
      return "00";
    }
    let str = amount.toString();
    return str.slice(-2);
  }

  static getHoursAndMinutes(minutes) {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    if (h === 0) {
      return m + " mins";
    } else {
      return h + " hrs, " + m + " mins";
    }
  }

  static generateGuid() {
    const S4 = () =>
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  static orderByDate(arr, dateProp) {
    // dateprop must be timestamp?
    return arr.slice().sort((a, b) => {
      return a[dateProp] > b[dateProp] ? -1 : 1;
    });
  }

  static getDownloadLink(platform) {
    const tracking = !!store.getState().tracking.utmArray[0]
      ? "?" + store.getState().tracking.utmArray[0]
      : "";

    if (isMobile) {
      return LINKS.OneLink + tracking;
    } else {
      if (
        typeof platform === "string" &&
        (platform.toLowerCase() === "google" ||
          platform.toLowerCase() === "android" ||
          platform.toLowerCase() === "googleplay" ||
          platform.toLowerCase() === "playstore")
      ) {
        return LINKS.Android;
      } else {
        return LINKS.iOS;
      }
    }
  }

  static isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  static arrayContains(needle, arrhaystack) {
    return arrhaystack.indexOf(needle) > -1;
  }

  static isValidEmail(query) {
    if (!query) {
      return false;
    }

    let emailBeforeAtSign = query.substr(0, query.indexOf("@"));

    emailBeforeAtSign = emailBeforeAtSign.split(".").join("");
    const emailAfterAtSign = query.substr(query.indexOf("@"), query.length);

    const email = emailBeforeAtSign + emailAfterAtSign;

    const emailToTest = email.replace(/\s/g, "");

    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailToTest).toLowerCase());
  }

  /**
   * Shuffles array in place. ES6 version
   * @param {Array} a items An array containing the items.
   */
  static shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  static getSeason(month) {
    if (month === "3" || month === "4" || month === "5") {
      return "SPRING";
    } else if (month === "6" || month === "7" || month === "8") {
      return "SUMMER";
    } else if (month === "9" || month === "10" || month === "11") {
      return "AUTUMN";
    }
    return "WINTER";
  }

  static isValidMobile(str) {
    let isBlank = !str || /^\s*$/.test(str);
    if (isBlank) return false;
    if (str.length < 5) return false;

    const strFirstThree = str.substring(0, 3);

    // +441892870777 should not be valid - landline

    // +447809090090 is valid
    if (strFirstThree === "+44") {
      if (str.charAt(3) !== "7") return false; // Must start with 07
      const pn = new PhoneNumber(str, "UK");
      return pn.isValid();
    }

    // 447809090090 is valid
    if (strFirstThree === "447" || strFirstThree === "440") {
      if (str.charAt(2) !== "7") return false; // Must start with 07
      const pn = new PhoneNumber(`+${str}`, "UK");
      return pn.isValid();
    }

    // 07809090090 is valid
    if (strFirstThree.charAt(0) === "0" && strFirstThree.charAt(1) === "7") {
      const pn = new PhoneNumber(`+44${str}`, "UK");
      return pn.isValid();
    }

    // Nothing else is valid
    return false;
  }

  static getFinalPrice(selectedVanSizeIndex, bookingPricesCustomChoices) {
    const selectedVanPriceData =
      bookingPricesCustomChoices.vanSizes[
      CUSTOM_VAN_CHOICES[selectedVanSizeIndex].vanSize
      ];
    const selectedVanData = CUSTOM_VAN_CHOICES[selectedVanSizeIndex];
    const estimateFinalReducer =
      selectedVanData.possibleOptions[selectedVanPriceData.selectedHelperIndex]
        .estimateFromReducer;

    let finalPrice = _.isNil(selectedVanPriceData[estimateFinalReducer])
      ? 0
      : selectedVanPriceData[estimateFinalReducer].estimate;

    return finalPrice;
  }

  static getFinalDeposit(selectedVanSizeIndex, bookingPricesCustomChoices) {
    const selectedVanPriceData =
      bookingPricesCustomChoices.vanSizes[
      CUSTOM_VAN_CHOICES[selectedVanSizeIndex].vanSize
      ];
    const selectedVanData = CUSTOM_VAN_CHOICES[selectedVanSizeIndex];
    const estimateFinalReducer =
      selectedVanData.possibleOptions[selectedVanPriceData.selectedHelperIndex]
        .estimateFromReducer;

    let finalDeposit = _.isNil(selectedVanPriceData[estimateFinalReducer])
      ? 0
      : selectedVanPriceData[estimateFinalReducer].deposit;

    return finalDeposit;
  }

  static getFinalReward(selectedVanSizeIndex, bookingPricesCustomChoices) {
    const selectedVanPriceData =
      bookingPricesCustomChoices.vanSizes[
      CUSTOM_VAN_CHOICES[selectedVanSizeIndex].vanSize
      ];
    const selectedVanData = CUSTOM_VAN_CHOICES[selectedVanSizeIndex];
    const estimateFinalReducer =
      selectedVanData.possibleOptions[selectedVanPriceData.selectedHelperIndex]
        .estimateFromReducer;

    let finalReward = _.isNil(selectedVanPriceData[estimateFinalReducer])
      ? 0
      : selectedVanPriceData[estimateFinalReducer].reward;

    return finalReward;
  }

  static getFinalEstimate(selectedVanSizeIndex, bookingPricesCustomChoices) {
    const selectedVanPriceData =
      bookingPricesCustomChoices.vanSizes[
      CUSTOM_VAN_CHOICES[selectedVanSizeIndex].vanSize
      ];
    const selectedVanData = CUSTOM_VAN_CHOICES[selectedVanSizeIndex];
    const estimateFinalReducer =
      selectedVanData.possibleOptions[selectedVanPriceData.selectedHelperIndex]
        .estimateFromReducer;

    let finalEstimate = _.isNil(selectedVanPriceData[estimateFinalReducer])
      ? 0
      : selectedVanPriceData[estimateFinalReducer].estimate;

    return finalEstimate;
  }

  static getPoundsAndPenniesWithNoSign(amount) {
    if (!amount) return "0.0";
    return this.getPounds(amount) + "." + this.getPennies(amount);
  }

  static getFinalEstimateObject(
    selectedVanSizeIndex,
    bookingPricesCustomChoices
  ) {
    const selectedVanPriceData =
      bookingPricesCustomChoices.vanSizes[
      CUSTOM_VAN_CHOICES[selectedVanSizeIndex].vanSize
      ];
    const selectedVanData = CUSTOM_VAN_CHOICES[selectedVanSizeIndex];
    const estimateFinalReducer =
      selectedVanData.possibleOptions[selectedVanPriceData.selectedHelperIndex]
        .estimateFromReducer;

    let finalEstimate = _.isNil(selectedVanPriceData[estimateFinalReducer])
      ? 0
      : selectedVanPriceData[estimateFinalReducer];

    return finalEstimate;
  }

  /*   static getFormattedLoadingTime(duration) {
    const TIME_FORMAT = "H [h] : mm [min]";

    if (duration) {
      return moment
        .utc(moment.duration(duration, "minutes").asMilliseconds())
        .format(TIME_FORMAT);
    }

    return "";
  } */
}

export default Utils;
