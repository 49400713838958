import Api from '../../../api';

export const EASY_BOOKING_PAYMENT_IN_PROGRESS = 'EASY_BOOKING_PAYMENT_IN_PROGRESS';
export const EASY_BOOKING_ERROR = 'EASY_BOOKING_ERROR';
export const EASY_BOOKING_SUCCESS = 'EASY_BOOKING_SUCCESS';

export const EASY_BOOKING_REQUIRES_SCA_AUTH = 'EASY_BOOKING_REQUIRES_SCA_AUTH';

export function easyBookingPaymentError(err) {
  return {
    type: EASY_BOOKING_ERROR,
    payload: err
  }
}

export function makeEasyBooking(bookingRef, payment_method_id, payment_intent_id) {
  return (dispatch, getState) => {

    dispatch({type: EASY_BOOKING_PAYMENT_IN_PROGRESS});

    const userDetails = getState().userDetails;

    const params = {
      guid: bookingRef, // The guid emailed out and put into url parameter emailBookingRef - should be exactly 36 characters long
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phone: userDetails.mobileNumber
    };

    if (payment_method_id) params.payment_method_id = payment_method_id;
    if (payment_intent_id) params.payment_intent_id = payment_intent_id;

    Api.post('w/book-quote', params)
      .then(resp => {

        if (resp.requires_action) {

          dispatch({
            type: EASY_BOOKING_REQUIRES_SCA_AUTH,
            payload: {
              payment_intent_client_secret: resp.payment_intent_client_secret
            }
          });

          return;
        }

        if (resp.err) return dispatch(easyBookingPaymentError(resp.err));

        dispatch({type: EASY_BOOKING_SUCCESS})

      })
  }
}
