import {createLogger} from 'redux-logger'
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import howItWorks from './reducers/howItWorks';
import getAQuote from './reducers/getAQuote';
import userInfo from './reducers/userInfo';
import bookingAddresses from './reducers/bookingAddresses';
import bookingDistance from './reducers/bookingDistance';
import bookingGeneral from './reducers/bookingGeneral';
import bookingPayment from './reducers/bookingPayment';
import bookingPrice from './reducers/bookingPrice';
import currentModal from './reducers/currentModal';
import driverForm from './reducers/drivers';
import auth from './reducers/auth';
import userDetails from './reducers/userDetails';
import cookies from './reducers/cookies';
import reservation from './reducers/reservation';
import tracking from './reducers/tracking';
import unfinishedAddressQueries from './reducers/unfinishedAddressQueriesReducer';
import bookingPricesCustomChoices from './reducers/bookingPricesCustomChoices';
import bookingAdditionalStops from './reducers/bookingAdditionalStops';
import easyBooking from './reducers/easyBooking';
import yourQuoteIsValidFor from './reducers/yourQuoteIsValidFor';
import storage from 'redux-persist/es/storage';
import { SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME } from './components/booking/YourQuoteIsValidFor/YourQuoteIsValidForActions';

const customLogger = createLogger({
  predicate: (getState, action) => action.type !== SAVE_YOUR_QUOTE_IS_VALID_FOR_TIME
});

const persistConfig = { // root persist config
  key: 'root',
  storage: storage,
  whitelist: ['userDetails', 'getAQuote', 'cookies', 'bookingDistance', 'bookingGeneral', 'bookingPrice', 'tracking', 'bookingAddresses', 'bookingPricesCustomChoices', 'bookingAdditionalStops'],
  version: '0'
};

const reservationConfig = {
  key: 'reservation',
  storage: storage,
  whitelist: ['reservationId'] // Keep reservation id (resets when paid for, replaced by new reservationId when new reservation is made)
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['hasAccount', 'hasEmailAndPassword']
};

const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  howItWorks,
  getAQuote,
  userInfo,
  bookingAdditionalStops,
  bookingAddresses,
  bookingDistance,
  bookingGeneral,
  bookingPayment,
  bookingPrice,
  currentModal,
  easyBooking: easyBooking,
  driverForm,
  userDetails,
  reservation: persistReducer(reservationConfig, reservation),
  cookies,
  unfinishedAddressQueries,
  bookingPricesCustomChoices,
  tracking,
  yourQuoteIsValidFor
});


const pReducer = persistReducer(persistConfig, reducer);

let storeWithConditionalLogger;

if (`${process.env.GATSBY_API_URL}` === 'https://api.kiffgo.com/') {
  storeWithConditionalLogger = createStore(
    pReducer,
    applyMiddleware(thunk)
  );
} else {
  storeWithConditionalLogger = createStore(
    pReducer,
    applyMiddleware(thunk, customLogger)
  );
}

export const store = storeWithConditionalLogger;

export const persistor = persistStore(store);
