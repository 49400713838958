import { createMuiTheme } from "@material-ui/core"

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#21EECA',
      main: '#c50000',
      dark: '#bf2e1e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3e3e3e',
      main: '#343434',
      dark: '#1b1b1b',
      contrastText: '#fff',
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans', `sans-serif`, `Tahoma`
    ].join(',')
  },
  spacing: {}
});

export default MuiTheme;
