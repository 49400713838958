import update from "immutability-helper";
import { UPDATE_DISTANCE_DETAILS } from "../actions/DistanceActions";
import { RESET_BOOKING } from "../containers/bookingStep5/BookingSuccess/BookingSuccessActions";
import { CLEAR_ADDRESS } from "../actions/AddressActions";

const initialState = {
  distance: 0,
  duration: 0,
  minimumTimeHours: 2,
};

function booking(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DISTANCE_DETAILS:
      return update(state, {
        distance: { $set: action.payload.resp.distance },
        duration: { $set: action.payload.resp.duration },
        minimumTimeHours: {
          $set:
            action.payload.resp.minimumTimeHours < 2
              ? 2
              : action.payload.resp.minimumTimeHours,
        },
      });
    case CLEAR_ADDRESS:
      return update(state, {
        distance: { $set: 0 },
        duration: { $set: 0 },
      });

    case RESET_BOOKING:
      return initialState; // also removes one use token
    default:
      return state;
  }
}

export default booking;
