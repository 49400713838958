import update from 'immutability-helper';
import { ADD_NEW_UTM_STRING } from "../containers/bootstrap/Analytics/AnalyticsActions"
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  utmArray: []
};

function tracking(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_UTM_STRING:
      return update(state, {
        utmArray: {$push: [action.payload.utmString]}
      });
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default tracking;
