// import 'babel-polyfill';
import React, { Component } from 'react';
import { CookieConsent } from './CookieConsent';
import connect from 'react-redux/es/connect/connect';
import { acknowledgeCookies } from './CookiesConsentActions';
import { isMobile } from 'react-device-detect';

class CookieConsentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    if (!this.props.cookies.gdprConsentAcknowledged) {
      this.setState({open: true});
      if (isMobile) {
        setTimeout(() => {
          window.Chatra && window.Chatra.hide();
        }, 500);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cookies.gdprConsentAcknowledged && !this.props.cookies.gdprConsentAcknowledged) {
      this.setState({open: false});
      if (isMobile) {
        setTimeout(() => {
          window.Chatra && window.Chatra.show();
        }, 500);
      }
    }
  }

  render() {
    return (
      <CookieConsent open={this.state.open} {...this.props} />
    );
  }
}

function mapStateToProps(state) {
  return {
    cookies: state.cookies
  }
}

function mapDispatchToProps(dispatch) {
  return {
    acknowledgeCookies: () => dispatch(acknowledgeCookies())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsentContainer);
