const COLORS = {
  // Shades:
  background: "#f9f9f9",
  border: "rgba(0,0,0,0.06)",
  offWhite: "#eee",
  offBlack: "#373737",
  lightBlack: "#5b656e",
  whiteOpaque: "rgba(255,255,255,0.85)",
  white: "#fff",
  black: "#000",
  justOffBlack: "#272727",
  orange: "orange",
  disabledButtonBg: "#e0e0e0",

  // Pastel:
  hotPink: "#ff436d",
  deepPurple: "#983eeb",
  pastelBlue: "#47a4ff",
  blueGreen: "#0ACE9B",
  brightYellow: "#f6e754",
  amber: "#ffa000",

  // Kiffgo:
  kgDarkGreen: "#0f765e",
  kgMidGreen: "#1bae74",
  kgGreen: "#00D7A6",
  kgAqua: "#21eeca",
  kgAquaBlue: "#1ee0e3",
  kgWhiteGreen: "#effff4",
  mainBackground: "#f3f3f3",

  kgGreenOpaque: "#e2faf3",

  // Misc:
  shutterStockYellow: "#ffe02d",
  starYellow: "#f1d015",

  // Paper:
  paper: "#f7f7f8",
  lightPaper: "#fbfbfb",
  paperText: "#848793",
  paperTitle: "#484848",

  // Allure: https://dribbble.com/shots/6102653-allure
  allureBlue: "#05e9e7",
  allureBlueDark: "#034581",
  allureGreen: "#04a00b",
  allureOrange: "#ffb10d",
  allureRed: "#ff3303",
  allurePink: "#ff4c8f",

  orangeText: "#ff8600",

  // Lugg:
  luggBlue: "#283060",

  //JJD:
  jjdGreyish: "#2c2c2c",
  jjdRed: "#c50000",
  jjdMaroon: "#290000",
  footerText: "#ec0707",
  greyishText: "#707070",
  greyishSpan: "#787878",
};

export default COLORS;
