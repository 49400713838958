import Api from '../api/index';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT';
export const VERIFICATION_CODE_FAILED_TO_SEND = 'VERIFICATION_CODE_FAILED_TO_SEND';

export function sendVerificationCode(mobileNumber) {
  return dispatch => {

    dispatch({
      type: SEND_VERIFICATION_CODE,
      payload: {
        mobileNumber: mobileNumber
      }
    });

    Api.post('w/login/request-code', {phone: mobileNumber})
      .then(resp => {
        if (resp.success) {

          ReactGA.event({
            category: 'Web booking',
            action: 'Verification code sent',
            label: 'User requested SMS validation'
          });
          ReactPixel.track('Lead');

          dispatch({
            type: VERIFICATION_CODE_SENT
          });

          return
        }

        ReactGA.event({
          category: 'Web booking',
          action: 'Verification code failed to send',
          label: 'User requested SMS validation'
        });

        dispatch({
          type: VERIFICATION_CODE_FAILED_TO_SEND,
          payload: {
            errorMessage: resp.err
          }
        })
      })
  }
}

export function setPersonalDetails(firstName, lastName, mobileNumber, email) {
  return {
    type: SET_PERSONAL_DETAILS,
    payload: {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      email: email
    }
  }
}
