export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export function setCurrentModal(modalName) {
  return {
    type: SET_CURRENT_MODAL,
    payload: modalName
  }
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  }
}
