import Icon0 from "@material-ui/icons/Block";
import Icon1 from "@material-ui/icons/AirlineSeatReclineNormal";
import Icon2 from "@material-ui/icons/PersonAdd";
import Icon3 from "@material-ui/icons/People";
import React from "react";
import COLORS from "../styles/colors";

export const LIFTING_CHOICES = [
  "Self load", // todo: safely change to 'Self load',
  "Driver help",
  "Driver + 1 helper",
  "Driver + 2 helpers",
];

// export const LIFTING_CHOICES_API = [0, 1, 2, 3];

export const LIFTING_CHOICES_API = [
  {
    helperCount: 0,
    description: "No I will do it myself. Selfload",
    estimateFromReducer: "estimateHelp0",
  },
  {
    helperCount: 1,
    description: "Driver help. I will help the driver with heavy item(s)",
    estimateFromReducer: "estimateHelp1",
  },
  {
    helperCount: 2,
    description: "2 Men Team. I don’t need to lift a finger",
    estimateFromReducer: "estimateHelp2",
  },
  {
    helperCount: 3,
    description: "3 Men Team. I am the boss, bring me the red carpet",
    estimateFromReducer: "estimateHelp3",
  },
];

/* export const LIFTING_CHOICES_API = [
  'No driver assistance',
  'With driver assistance',
  'Driver + 1 extra helper',
  'Driver + 2 extra helpers'
]; */

export const LIFTING_CHOICES_TWO = {
  "No assistance": {
    id: 0,
    name: "No assistance",
    icon: <Icon0 style={{ color: COLORS.hotPink, paddingRight: "12px" }} />,
  },
  "With driver assistance": {
    id: 1,
    name: "With driver assistance",
    icon: <Icon1 style={{ color: COLORS.hotPink, paddingRight: "12px" }} />,
  },
  "Driver + 1 extra helper": {
    id: 2,
    name: "Driver + 1 extra helper",
    icon: <Icon2 style={{ color: COLORS.hotPink, paddingRight: "12px" }} />,
  },
  "Driver + 2 extra helpers": {
    id: 3,
    name: "Driver + 2 extra helpers",
    icon: <Icon3 style={{ color: COLORS.hotPink, paddingRight: "12px" }} />,
  },
};
