// Set prices when quotes have been returned
import Api from '../../../api/index';
import { saveLead } from '../../../actions/LeadActions';

export const PARTIAL_SIGNUP_FROM_SERVICE_PAGE = 'PARTIAL_SIGNUP_FROM_SERVICE_PAGE';

export function partialSignupFromServicePage(firstName, lastName, email, phone, jobName) {
  return (dispatch, getState) => {

    // Params
    const params = {
      name: firstName + ' ' + lastName,
      email: email,
      phone: phone,
      utmArray: getState().tracking.utmArray
    };

    // Save in redux store - also saved in Booking reducer
    dispatch({
      type: PARTIAL_SIGNUP_FROM_SERVICE_PAGE,
      payload: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        jobName: jobName
      }
    });

    dispatch(saveLead(phone))

    Api.post('website/user-details', params)
      .then(() => {
        // nothing
      });

  }
}
