import { Component } from 'react';
import { setupCsrf } from './SecurityActions';
import connect from 'react-redux/es/connect/connect'

class SecurityContainer extends Component {
  componentWillMount() {
    this.props.setupCsrf()
  }

  render() {
    return (
      null
    );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    setupCsrf: () => dispatch(setupCsrf())
  }
}

export default connect(null, mapDispatchToProps)(SecurityContainer);
