import update from 'immutability-helper';
import {
  SEND_VERIFICATION_CODE,
  SET_PERSONAL_DETAILS,
} from '../actions/PersonalDetailsActions';
import { SUBMIT_USER_INFO } from '../containers/heroes/RemindMeLater/RemindMeLaterActions';
import {
  SET_CARD_DETAILS_FROM_LOGIN,
  SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN
} from '../containers/modals/VerifyMobile/VerifyMobileModalActions';
import { RESET_CARD_INFO } from '../containers/bookingStep4/Payment/PaymentActions';
import { SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN } from '../containers/modals/Login/LoginModalActions';
import {
  SAVING_CARD_DETAILS,
  SET_CARD_DETAILS_AND_PAY,
  SET_CARD_DETAILS_FAILED
} from '../containers/bookingStep4/StripeAddCard/StripeAddCardActions';
import { SET_USER_DETAILS_FROM_RESERVATION_MAKER } from '../containers/modals/ReserveBooking/ReserveBookingModalActions';
import { PARTIAL_SIGNUP_FROM_SERVICE_PAGE } from '../containers/heroes/ServiceHero/ServiceHeroActions';
import { ADD_USER_DETAILS_FROM_JUKEBOX } from '../containers/bootstrap/Analytics/AnalyticsActions';
import { SET_EMAIL, SET_FIRST_NAME, SET_LAST_NAME, SET_MOBILE_NUMBER } from '../actions/UserDetailsActions';
import { SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF } from '../containers/easyBooking/EasyBooking/EasyBookingActions';
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  name: '', // from /get-a-quote page
  firstName: '',
  lastName: '',
  mobileNumber: '',
  email: '',
  hasCard: false,
  savingCardDetails: false,
  setCardDetailsFailedError: null,
  card: {
    exp_month: '',
    exp_year: '',
    last4: ''
  }
};

function userDetails(state = initialState, action) {
  switch (action.type) {
    case SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF: // Grab their email at least ...
      return update(state, {
        email: {$set: action.payload.email}
      });
    case SET_EMAIL:
      return update(state, {
        email: {$set: action.payload.email}
      });
    case SET_MOBILE_NUMBER:
      return update(state, {
        mobileNumber: {$set: action.payload.mobileNumber}
      });
    case SET_FIRST_NAME:
      return update(state, {
        firstName: {$set: action.payload.firstName}
      });
    case SET_LAST_NAME:
      return update(state, {
        lastName: {$set: action.payload.lastName}
      });
    case ADD_USER_DETAILS_FROM_JUKEBOX:
      return update(state, {
        $merge: action.payload
      });
    case PARTIAL_SIGNUP_FROM_SERVICE_PAGE:
      return update(state, {
        firstName: {$set: action.payload.firstName},
        lastName: {$set: action.payload.lastName},
        email: {$set: action.payload.email},
        mobileNumber: {$set: action.payload.phone}
      });
    case SEND_VERIFICATION_CODE:
      return update(state, {
        mobileNumber: {$set: action.payload.mobileNumber}
      });
    case SET_USER_DETAILS_FROM_RESERVATION_MAKER:
      return update(state, {
        mobileNumber: {$set: action.payload.mobileNumber},
        email: {$set: action.payload.email}
      });
    case SET_PERSONAL_DETAILS:
      return update(state, {
        firstName: {$set: action.payload.firstName},
        lastName: {$set: action.payload.lastName},
        mobileNumber: {$set: action.payload.mobileNumber},
        email: {$set: action.payload.email}
      });
    case SUBMIT_USER_INFO: // from /get-a-quote page
      return update(state, {
        name: {$set: action.payload.name},
        firstName: {$set: action.payload.firstName},
        lastName: {$set: action.payload.lastName},
        mobileNumber: {$set: action.payload.phone},
        email: {$set: action.payload.email}
      });
    case SET_CARD_DETAILS_FROM_LOGIN:
      return update(state, {
        hasCard: {$set: true},
        card: {$set: action.payload.card}
      });
    case RESET_CARD_INFO:
      return update(state, {
        hasCard: {$set: false},
        card: {
          exp_month: {$set: ''},
          exp_year: {$set: ''},
          last4: {$set: ''}
        }
      });
    case SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN:
      return update(state, {
        firstName: {$set: action.payload.firstName},
        lastName: {$set: action.payload.lastName},
        email: {$set: action.payload.email},
        mobileNumber: {$set: action.payload.phone}
      });
    case SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN:
      return update(state, {
        firstName: {$set: action.payload.firstName},
        lastName: {$set: action.payload.lastName},
        email: {$set: action.payload.email},
        mobileNumber: {$set: action.payload.phone}
      });
    case SAVING_CARD_DETAILS:
      return update(state, {
        savingCardDetails: {$set: true},
        setCardDetailsFailedError: {$set: null},
      });
    case SET_CARD_DETAILS_AND_PAY:
      return update(state, {
        hasCard: {$set: true},
        card: {$set: action.payload.card},
        savingCardDetails: {$set: false},
        setCardDetailsFailedError: {$set: null},
      });
    case SET_CARD_DETAILS_FAILED:
      return update(state, {
        savingCardDetails: {$set: false},
        setCardDetailsFailedError: {$set: action.payload.errorMessage},
      });
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default userDetails;
