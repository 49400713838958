export const EDIT_PICKUP_ADDRESS_MODAL = 'EDIT_PICKUP_ADDRESS_MODAL';
export const EDIT_DROPOFF_ADDRESS_MODAL = 'EDIT_DROPOFF_ADDRESS_MODAL';
export const LOGIN_MODAL = 'LOGIN_MODAL';
export const VERIFY_MOBILE_MODAL = 'VERIFY_MOBILE_MODAL';
export const CREATE_PASSWORD_MODAL = 'CREATE_PASSWORD_MODAL';
export const RESERVE_BOOKING_MODAL = 'RESERVE_BOOKING_MODAL';

export const EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL = 'EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL';
export const EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL = 'EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL';
export const EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL = 'EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL';
export const EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL = 'EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL';
