export const ON_INVENTORY_ADD = "ON_INVENTORY_ADD";
export const ON_INVENTORY_REMOVE = "ON_INVENTORY_REMOVE";

export function inInventoryAdd(title, quantity) {
  return (dispatch) => {
    dispatch({
      type: ON_INVENTORY_ADD,
      payload: {
        title,
        quantity,
      },
    });
  };
}

export function onInventoryRemove(index) {
  return (dispatch) => {
    dispatch({
      type: ON_INVENTORY_REMOVE,
      index,
    });
  };
}
