// Set prices when quotes have been returned
import Api from '../../../api/index';
import ReactPixel from 'react-facebook-pixel';
import Utils from '../../../library/utils';

export const SET_PRICES = 'SET_PRICES';

// Errors
export const SHOW_POSTCODE_ERROR = 'SHOW_POSTCODE_ERROR';
export const HIDE_POSTCODE_ERROR = 'HIDE_POSTCODE_ERROR';

// Loading quote or status resolved
export const VALIDATING_POSTCODES = 'VALIDATING_POSTCODES';
export const VALIDATING_POSTCODES_RESOLVED = 'VALIDATING_POSTCODES_RESOLVED';

// Show / hide quotes
export const SHOW_QUOTES = 'SHOW_QUOTES';
export const HIDE_AND_RESET_QUOTES = 'HIDE_AND_RESET_QUOTES';

export function generateQuotes(pickupPostcode, dropoffPostcode) {
  return (dispatch) => {

    // dispatch({ //todo: remove ... this is just for testing
    //   type: SHOW_QUOTES
    // });
    // dispatch({
    //   type: SET_PRICES,
    //   payload: {
    //     medium: 12,
    //     long: 123,
    //     luton: 341281
    //   }
    // });
    // return; //todo: remove ... this is just for testing

    if (Utils.isBlank(pickupPostcode) || Utils.isBlank(dropoffPostcode)) {
      dispatch({
        type: VALIDATING_POSTCODES_RESOLVED
      });
      dispatch({
        type: SHOW_POSTCODE_ERROR,
        payload: {
          errorMessage: 'To get a quote, please use enter the postcodes above or call us on 0203 870 0974'
        }
      });
      return;
    }

    const params = {
      startPostcode: pickupPostcode.replace(/\s/g, ''),
      endPostcode: dropoffPostcode.replace(/\s/g, '')
    };

    dispatch({
      type: VALIDATING_POSTCODES
    });
    dispatch(hidePostcodeError());

    Api.post('pricing/grid', params)
      .then((resp) => {
        dispatch({
          type: VALIDATING_POSTCODES_RESOLVED
        });

        if (resp.startAvailabeInArea === false || resp.startAvailabeInArea === false) {
          dispatch({
            type: SHOW_POSTCODE_ERROR,
            payload: {
              errorMessage: 'JJD is not available in your area yet'
            }
          });
        } else if (resp.endPostcodeValid === false && resp.startPostcodeValid === false) {
          dispatch({
            type: SHOW_POSTCODE_ERROR,
            payload: {
              errorMessage: 'Please enter valid postcodes or call 0203 870 0974 if you\'re struggling'
            }
          });
        } else if (resp.endPostcodeValid === false) {
          dispatch({
            type: SHOW_POSTCODE_ERROR,
            payload: {
              errorMessage: 'Please enter a valid dropoff postcode'
            }
          });
        } else if (resp.startPostcodeValid === false) {
          dispatch({
            type: SHOW_POSTCODE_ERROR,
            payload: {
              errorMessage: 'Please enter a valid pickup postcode'
            }
          });
        } else if (resp.startPostcodeValid === true && resp.endPostcodeValid === true) {
          dispatch({
            type: SHOW_QUOTES,
            payload: {
              pickupPostcode: pickupPostcode,
              dropoffPostcode: dropoffPostcode
            }
          });
          dispatch({
            type: SET_PRICES,
            payload: {
              medium: resp.medium,
              long: resp.long,
              luton: resp.luton
            }
          });
          // Pixel track view
          ReactPixel.track('ViewContent', {
            content_type: 'Van Grid',
            content_category: 'Rough Pricing'
          });
        } else {
          dispatch({
            type: SHOW_POSTCODE_ERROR,
            payload: {
              errorMessage: 'Unknown error'
            }
          });
        }
      })

      .catch(() => {
        dispatch({
          type: SHOW_POSTCODE_ERROR,
          payload: {
            errorMessage: 'Server error'
          }
        });
        dispatch({
          type: VALIDATING_POSTCODES_RESOLVED
        })
      })
  }
}

export function hidePostcodeError() {
  return {
    type: HIDE_POSTCODE_ERROR
  }
}

export function returnToPostcodesInput() {
  return {
    type: HIDE_AND_RESET_QUOTES
  }
}
