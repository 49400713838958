import { ACKNOWLEDGE_COOKIES } from '../containers/bootstrap/CookieConsent/CookiesConsentActions';
import update from 'immutability-helper';

const initialState = {
  gdprConsentAcknowledged: false
};

function cookies(state = initialState, action) {
  switch (action.type) {
    case ACKNOWLEDGE_COOKIES:
      return update(state, {
        gdprConsentAcknowledged: {$set: true}
      });
    default:
      return state
  }
}

export default cookies;
