import { MAKE_PAYMENT_FAILED } from '../Payment/PaymentActions';

export const SET_CARD_DETAILS_AND_PAY = 'SET_CARD_DETAILS_AND_PAY';
export const SAVING_CARD_DETAILS = 'SAVING_CARD_DETAILS';
export const SET_CARD_DETAILS_FAILED = 'SET_CARD_DETAILS_FAILED';
export const RECEIVE_TOKEN_FOR_GUEST_PAYMENT = 'RECEIVE_TOKEN_FOR_GUEST_PAYMENT';

export function receiveToken(token) {
  return {
    type: RECEIVE_TOKEN_FOR_GUEST_PAYMENT,
    payload: {
      token: token.id
    }
  }
}

export function paymentFailed(errorMessage) {
  return {
    type: MAKE_PAYMENT_FAILED,
    payload: {
      errorMessage: errorMessage
    }
  }
}
