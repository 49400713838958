import Api from "../api/index";
import { calculateCustomPrices, calculateNewPrice } from "./PriceActions";
import Utils from "../library/utils";

export const CALCULATE_DISTANCE = "CALCULATE_DISTANCE";
export const UPDATE_DISTANCE_DETAILS = "UPDATE_DISTANCE_DETAILS";
export const ERROR_UPDATING_DISTANCE = "ERROR_UPDATING_DISTANCE";

const moment = require("moment");

export function estimateNewDistance(ignorePricesForAllVanSizes) {
  return (dispatch, getState) => {
    dispatch({
      type: CALCULATE_DISTANCE,
    });

    const bookingAddresses = Object.assign({}, getState().bookingAddresses);
    const bookingGeneral = Object.assign({}, getState().bookingGeneral);
    const additionalPostcodes = [];
    for (const stop of getState().bookingAdditionalStops.stops) {
      if (!Utils.isBlank(stop.postcode))
        additionalPostcodes.push(stop.postcode);
    }

    let arrival = `${moment().add(4, "d").format("YYYY-MM-DD")} 17:00`;
    if (bookingGeneral.arrival) {
      arrival = bookingGeneral.arrival;
    }

    Api.post("w/pricing/distance-duration", {
      startPostcode: bookingAddresses.pickup.postcode,
      endPostcode: bookingAddresses.dropoff.postcode,
      pickup: arrival,
      additionalPostcodes: additionalPostcodes,
    })
      .then((resp) => {
        if (resp.err) {
          dispatch({
            type: ERROR_UPDATING_DISTANCE,
          });

          return;
        }

        dispatch({
          type: UPDATE_DISTANCE_DETAILS,
          payload: {
            resp: resp,
          },
        });

        if (!ignorePricesForAllVanSizes) dispatch(calculateCustomPrices());
        dispatch(calculateCustomPrices()); // Calculate new price if distance changes
      })
      .catch(() => {
        dispatch({
          type: ERROR_UPDATING_DISTANCE,
        });
      });
  };
}
