import Api from '../../../api/index';
import { removeAllAdditionalStops } from '../../../actions/AdditionalStopsActions';
import Utils from '../../../library/utils';
import { estimateNewDistance } from '../../../actions/DistanceActions';

export const SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF = 'SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF';
export const EMAIL_BOOKING_REF_ERROR = 'EMAIL_BOOKING_REF_ERROR';
export const CLEAR_EMAIL_BOOKING_REF_ERROR = 'CLEAR_EMAIL_BOOKING_REF_ERROR';

const moment = require('moment');

export function getEmailBookingRefDetails(guidFromURL) {
  return dispatch => {

    dispatch({type: CLEAR_EMAIL_BOOKING_REF_ERROR});

    if (!guidFromURL || guidFromURL.length !== 36) return dispatch({type: EMAIL_BOOKING_REF_ERROR, payload: `We're sorry, we could not find your quote.`});

    Api.post('w/fetch-quote', {guid: guidFromURL})
      .then(resp => {

        if (resp.err) dispatch({type: EMAIL_BOOKING_REF_ERROR, payload: resp.err});
        if (!resp.err) dispatch({type: SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF, payload: resp});

      })
      .catch(() => {

        dispatch({type: EMAIL_BOOKING_REF_ERROR, payload: `We're sorry, we could not find your quote.`});

      })
  }
}

export const POPULATE_ADDITIONAL_STOPS_FROM_EASY_BOOKING = 'POPULATE_ADDITIONAL_STOPS_FROM_EASY_BOOKING';
export const POPULATE_ADDRESSES_FROM_EASY_BOOKING = 'POPULATE_ADDRESSES_FROM_EASY_BOOKING';
export const POPULATE_GENERAL_BOOKING_INFO_FROM_EASY_BOOKING = 'POPULATE_GENERAL_BOOKING_INFO_FROM_EASY_BOOKING';

export function populateBookingFromEasyBooking() {
  return (dispatch, getState) => {

    const easyBooking = getState().easyBooking.details;

    // bookingAdditionalStops
    if (easyBooking.additionalStops && easyBooking.additionalStops.length > 0) {
      const additionalStopsFormatted = [];
      for (const stop of easyBooking.additionalStops) {
        additionalStopsFormatted.push(Utils.apiAddressToMyFormat(stop))
      }
      dispatch({type: POPULATE_ADDITIONAL_STOPS_FROM_EASY_BOOKING, payload: additionalStopsFormatted})
    } else {
      dispatch(removeAllAdditionalStops())
    }

    // bookingAddresses
    dispatch({
      type: POPULATE_ADDRESSES_FROM_EASY_BOOKING,
      payload: {
        pickup: Utils.apiAddressToMyFormat(easyBooking.pickupAddress),
        dropoff: Utils.apiAddressToMyFormat(easyBooking.dropoffAddress)
      }
    });

    // bookingGeneral
    const collectionStart = moment(easyBooking.pickup).format('HH');
    dispatch({
      type: POPULATE_GENERAL_BOOKING_INFO_FROM_EASY_BOOKING,
      payload: {
        vanSizeIndex: Utils.getVanSizeIndexFromSize(easyBooking.size),
        helpLiftingIndex: easyBooking.liftingPowerIndex,
        rideAlong: easyBooking.rideAlong,
        noOfStairs: easyBooking.stairs, // todo: this information is lost (per stairs) ...
        extraTime: 0, // todo: this information is lost ...
        name: easyBooking.name,
        jobDescription: easyBooking.jobDescription,
        arrival: moment(easyBooking.pickup).format('YYYY-MM-DD HH:mm'),

        //  Collection range:
        collectionRange: easyBooking.collectionRange,
        collectionStart: collectionStart,
        collectionEnd: collectionStart + easyBooking.collectionRange
      }
    });

    dispatch(estimateNewDistance())

  }
}
