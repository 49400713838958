import {
  calculateNewPrice,
  calculateCustomPrices,
} from "../../../actions/PriceActions";

export const SET_ASSISTANCE_LEVEL = "SET_ASSISTANCE_LEVEL";
export const TURN_RIDE_ALONG_ON = "TURN_RIDE_ALONG_ON";
export const TURN_RIDE_ALONG_OFF = "TURN_RIDE_ALONG_OFF";
export const SET_NUMBER_OF_STAIRS = "SET_NUMBER_OF_STAIRS";
export const SET_EXTRA_TIME_HOURS = "SET_EXTRA_TIME_HOURS";

export function setAssistanceLevel(index) {
  return (dispatch) => {
    dispatch({
      type: SET_ASSISTANCE_LEVEL,
      payload: {
        index: index,
      },
    });

    dispatch(calculateCustomPrices());
  };
}

export function turnRideAlongOn() {
  return {
    type: TURN_RIDE_ALONG_ON,
  };
}

export function turnRideAlongOff() {
  return {
    type: TURN_RIDE_ALONG_OFF,
  };
}

export function setNumberOfStairs(number) {
  return (dispatch) => {
    dispatch({
      type: SET_NUMBER_OF_STAIRS,
      payload: {
        amount: number,
      },
    });

    dispatch(calculateCustomPrices());
  };
}

export function setExtraTimeHours(hours) {
  return (dispatch) => {
    dispatch({
      type: SET_EXTRA_TIME_HOURS,
      payload: {
        amount: hours,
      },
    });

    dispatch(calculateCustomPrices());
  };
}
