import update from 'immutability-helper';
import {
  SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF,
  EMAIL_BOOKING_REF_ERROR,
  CLEAR_EMAIL_BOOKING_REF_ERROR
} from '../containers/easyBooking/EasyBooking/EasyBookingActions';
import {
  EASY_BOOKING_PAYMENT_IN_PROGRESS,
  EASY_BOOKING_ERROR,
  EASY_BOOKING_SUCCESS, EASY_BOOKING_REQUIRES_SCA_AUTH
} from '../containers/easyBooking/EasyBookingPayment/EasyBookingPaymentActions';

const initialState = {
  details: null,
  error: null,
  paymentInProgress: false,
  paymentError: null,
  paymentSuccess: false,
  obtainedSuccess: false,
  payment_intent_client_secret: null,
  requiresAction: false
};

function easyBooking(state = initialState, action) {
  switch (action.type) {
    case EASY_BOOKING_REQUIRES_SCA_AUTH:
      return update(state, {
        payment_intent_client_secret: {$set: action.payload.payment_intent_client_secret},
        requiresAction: {$set: true}
      });
    case EASY_BOOKING_PAYMENT_IN_PROGRESS:
      return update(state, {
        paymentInProgress: {$set: true}
      });
    case EASY_BOOKING_ERROR:
      return update(state, {
        paymentInProgress: {$set: false},
        paymentError: {$set: action.payload},
        requiresAction: {$set: false}
      });
    case EASY_BOOKING_SUCCESS:
      return update(state, {
        paymentInProgress: {$set: false},
        paymentError: {$set: null},
        paymentSuccess: {$set: true}
      });
    case CLEAR_EMAIL_BOOKING_REF_ERROR:
      return update(state, {
        error: {$set: null}
      });
    case SET_BOOKING_DETAILS_FROM_EMAIL_BOOKING_REF:
      return update(state, {
        details: {$set: action.payload},
        obtainedSuccess: {$set: true}
      });
    case EMAIL_BOOKING_REF_ERROR:
      return update(state, {
        error: {$set: action.payload}
      });
    default:
      return state
  }
}

export default easyBooking;
