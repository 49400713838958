import update from 'immutability-helper';
import { SESSION_TIMED_OUT } from '../api';
import {
  SEND_VERIFICATION_CODE,
  VERIFICATION_CODE_FAILED_TO_SEND, VERIFICATION_CODE_SENT
} from '../actions/PersonalDetailsActions';
import {
  CHECK_VERIFICATION_CODE,
  CHECK_VERIFICATION_CODE_FAILED,
  CHECK_VERIFICATION_CODE_SUCCESS,
  RESET_VERIFY_MOBILE,
  SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN
} from '../containers/modals/VerifyMobile/VerifyMobileModalActions';
import {
  ATTEMPT_LOGIN_WITH_EMAIL_AND_PASSWORD,
  LOGIN_WITH_EMAIL_AND_PASSWORD_FAILED,
  LOGIN_WITH_EMAIL_AND_PASSWORD_SUCCESSFUL,
  SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN
} from '../containers/modals/Login/LoginModalActions';
import {
  SET_PASSWORD_FAILED,
  SET_PASSWORD_SUCCESS,
  SETTING_PASSWORD
} from '../containers/modals/CreatePassword/CreatePasswordActions';
import { SET_CSRF_TOKEN } from "../containers/bootstrap/Security/SecurityActions"
import { IS_LOGGED_IN } from "../containers/auth/Auth/AuthActions"
import { WIPE_DATA } from '../containers/bookingStep5/BookingSuccess/BookingSuccessActions';

const initialState = {
  isLoggedIn: false, // will be used later
  _csrf: null,

  // send verification code:
  sendingVerificationCode: false,
  sendingVerificationCodeError: null,
  verificationCodeSent: false,

  // check verificationCode
  checkingVerificationCode: false,
  checkVerificationCodeError: null,
  phoneValidated: false,

  // email and password login:
  loggingInWithEmailAndPassword: false,
  loginWithEmailAndPasswordError: null,
  loginWithEmailAndPasswordSuccessful: false,

  // set email password:
  settingEmailPassword: false,
  setEmailPasswordSuccess: false,
  setEmailPasswordError: null,

  // persisted
  hasAccount: false,
  hasEmailAndPassword: false
};

function auth(state = initialState, action) {
  switch (action.type) {
    case SESSION_TIMED_OUT: // will be fetching new CSRF already
      return update(state, {
        isLoggedIn: {$set: false}
      });
    case SET_CSRF_TOKEN:
      return update(state, {
        _csrf: {$set: action.payload._csrf}
      });
    case SEND_VERIFICATION_CODE:
      return update(state, {
        sendingVerificationCode: {$set: true},
        sendingVerificationCodeError: {$set: null}
      });
    case VERIFICATION_CODE_FAILED_TO_SEND:
      return update(state, {
        sendingVerificationCode: {$set: false},
        sendingVerificationCodeError: {$set: action.payload.errorMessage}
      });
    case VERIFICATION_CODE_SENT:
      return update(state, {
        sendingVerificationCode: {$set: false},
        verificationCodeSent: {$set: true}
      });
    case CHECK_VERIFICATION_CODE:
      return update(state, {
        checkingVerificationCode: {$set: true},
        checkVerificationCodeError: {$set: null}
      });
    case CHECK_VERIFICATION_CODE_FAILED:
      return update(state, {
        checkingVerificationCode: {$set: false},
        checkVerificationCodeError: {$set: action.payload.errorMessage}
      });
    case CHECK_VERIFICATION_CODE_SUCCESS:
      return update(state, {
        checkingVerificationCode: {$set: false},
        checkVerificationCodeError: {$set: null},
        isLoggedIn: {$set: true},
        phoneValidated: {$set: true}
      });
    case IS_LOGGED_IN:
      return update(state, {
        isLoggedIn: {$set: true},
        phoneValidated: {$set: true}
      });
    case ATTEMPT_LOGIN_WITH_EMAIL_AND_PASSWORD:
      return update(state, {
        loggingInWithEmailAndPassword: {$set: true},
        loginWithEmailAndPasswordError: {$set: null}
      });
    case LOGIN_WITH_EMAIL_AND_PASSWORD_FAILED:
      return update(state, {
        loggingInWithEmailAndPassword: {$set: false},
        loginWithEmailAndPasswordError: {$set: action.payload.errorMessage}
      });
    case LOGIN_WITH_EMAIL_AND_PASSWORD_SUCCESSFUL:
      return update(state, {
        loggingInWithEmailAndPassword: {$set: false},
        loginWithEmailAndPasswordError: {$set: null},
        loginWithEmailAndPasswordSuccessful: {$set: true},
        isLoggedIn: {$set: true},
        hasEmailAndPassword: {$set: true}
      });
    case SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN:
      return update(state, {
        hasEmailAndPassword: {$set: true}
      });
    case SETTING_PASSWORD:
      return update(state, {
        settingEmailPassword: {$set: true}
      });
    case SET_PASSWORD_FAILED:
      return update(state, {
        settingEmailPassword: {$set: false},
        setEmailPasswordError: {$set: action.payload.errorMessage}
      });
    case SET_PASSWORD_SUCCESS:
      return update(state, {
        settingEmailPassword: {$set: false},
        setEmailPasswordSuccess: {$set: true},
        hasEmailAndPassword: {$set: true}
      });
    case SET_PERSONAL_DETAILS_FROM_PHONE_LOGIN:
      return update(state, {
        hasEmailAndPassword: {$set: action.payload.hasWebLogin}
      });
    case RESET_VERIFY_MOBILE:
      return update(state, {
        sendingVerificationCode: {$set: false},
        sendingVerificationCodeError: {$set: null},
        verificationCodeSent: {$set: false},
        checkingVerificationCode: {$set: false},
        checkVerificationCodeError: {$set: null},
        phoneValidated: {$set: false},
      });
    case WIPE_DATA:
      return initialState;
    default:
      return state
  }
}

export default auth;
