import { validatePostcode } from '../../bookingStep0/RoutePlanner/RoutePlannerActions';
import { validateAdditionalStopPostcode } from '../../../actions/AdditionalStopsActions';

export const SAVE_ADDRESS_MANUALLY = 'SAVE_ADDRESS_MANUALLY';
export const SAVE_ADDITIONAL_STOP_ADDRESS_MANUALLY = 'SAVE_ADDITIONAL_STOP_ADDRESS_MANUALLY';

export function saveAddressManually(whichAddress, line_1, line_2, line_3, post_town, postcode) {
  return dispatch => {

    const fullAddress = [line_1, line_2, line_3, post_town, postcode].filter(val => val && val.length > 0).join(', ');

    dispatch({
      type: SAVE_ADDRESS_MANUALLY,
      payload: {
        whichAddress: whichAddress,
        fullAddress: fullAddress,
        line_1: line_1,
        line_2: line_2,
        line_3: line_3,
        post_town: post_town,
        postcode: postcode
      }
    });

    dispatch(validatePostcode(whichAddress, postcode))
  }
}


export function saveAdditionalStopAddressManually(stopIndex, line_1, line_2, line_3, post_town, postcode) {
  return dispatch => {

    const fullAddress = [line_1, line_2, line_3, post_town, postcode].filter(val => val && val.length > 0).join(', ');

    dispatch({
      type: SAVE_ADDITIONAL_STOP_ADDRESS_MANUALLY,
      payload: {
        stopIndex: stopIndex,
        fullAddress: fullAddress,
        line_1: line_1,
        line_2: line_2,
        line_3: line_3,
        post_town: post_town,
        postcode: postcode
      }
    });

    dispatch(validateAdditionalStopPostcode(stopIndex, postcode))
  }
}
