import { LIFTING_CHOICES_API } from "./lifting";

export const VANS = {
  0: {
    index: 0,
    id: "small",
    name: "Small",
    apiName: "Small",
    img: require("../assets/vans/small-van.png"),
    imgRealistic: require("../assets/vans/small-van.png"),
  },

  1: {
    index: 1,
    id: "medium",
    name: "Medium",
    apiName: "Medium",
    img: require("../assets/vans/medium-van.png"),
    imgRealistic: require("../assets/vans/medium-van.png"),
  },
  2: {
    index: 2,
    id: "long",
    name: "Long",
    apiName: "Long",
    img: require("../assets/vans/large-van.png"),
    imgRealistic: require("../assets/vans/large-van.png"),
  },
  3: {
    index: 3,
    id: "luton",
    name: "Luton",
    apiName: "Luton",
    img: require("../assets/vans/xl-luton-1.png"),
    imgRealistic: require("../assets/vans/xl-luton-1.png"),
  },
  4: {
    index: 3,
    id: "luton",
    name: "Luton_2",
    apiName: "Luton_2",
    img: require("../assets/vans/xl-luton-2.png"),
    imgRealistic: require("../assets/vans/xl-luton-2.png"),
  },
  5: {
    index: 3,
    id: "luton",
    name: "Luton_3",
    apiName: "Luton_3",
    img: require("../assets/vans/xl-luton-3.png"),
    imgRealistic: require("../assets/vans/xl-luton-3.png"),
  },
};

export const CUSTOM_VAN_CHOICES = [
  {
    name: "Small Van",
    vanSize: "Small",
    vanSizeIndex: 0,
    estimateFromReducer: "estimateHelp0",
    liftingHelpIndex: 1,
    numberOfVehicles: 1,
    imgRealistic: VANS["0"].imgRealistic,

    description: "Suitable for small items delivery like an armchair",
    possibleOptions: [LIFTING_CHOICES_API[0], LIFTING_CHOICES_API[1]],
  },
  {
    name: "Medium Van",
    vanSize: "Medium",
    vanSizeIndex: 1,
    estimateFromReducer: "estimateHelp0",
    liftingHelpIndex: 1,
    numberOfVehicles: 1,
    imgRealistic: VANS["1"].imgRealistic,
    description: "Suitable for student move & 1 room move ",
    possibleOptions: [
      LIFTING_CHOICES_API[0],
      LIFTING_CHOICES_API[1],
      LIFTING_CHOICES_API[2],
      LIFTING_CHOICES_API[3],
    ],
  },
  {
    name: "Large Van",
    vanSize: "Long",
    vanSizeIndex: 2,
    estimateFromReducer: "estimateHelp0",
    liftingHelpIndex: 1,
    numberOfVehicles: 1,
    imgRealistic: VANS["2"].imgRealistic,
    description: "Suitable for 1 bed house move",
    possibleOptions: [
      LIFTING_CHOICES_API[0],
      LIFTING_CHOICES_API[1],
      LIFTING_CHOICES_API[2],
      LIFTING_CHOICES_API[3],
    ],
  },
  {
    name: "XL Van - Luton",
    vanSize: "Luton",
    estimateFromReducer: "estimateHelp0",
    liftingHelpIndex: 1,
    numberOfVehicles: 1,
    vanSizeIndex: 3,
    imgRealistic: VANS["3"].imgRealistic,
    description: "Suitable for 2 bed house move",
    possibleOptions: [
      LIFTING_CHOICES_API[0],
      LIFTING_CHOICES_API[1],
      LIFTING_CHOICES_API[2],
      LIFTING_CHOICES_API[3],
    ],
  },

  {
    name: "Two XL - Luton Vans",
    vanSize: "Luton_2",
    estimateFromReducer: "estimateHelp0",
    liftingHelpIndex: 1,
    numberOfVehicles: 1,
    vanSizeIndex: 4,
    imgRealistic: VANS["4"].imgRealistic,
    description: "Suitable for 3 bed house move",
    possibleOptions: [LIFTING_CHOICES_API[0], LIFTING_CHOICES_API[2]],
  },

  {
    name: "Three XL - Luton Vans",
    vanSize: "Luton_3",
    estimateFromReducer: "estimateHelp0",
    liftingHelpIndex: 1,
    numberOfVehicles: 1,
    vanSizeIndex: 5,
    imgRealistic: VANS["5"].imgRealistic,
    description: "Suitable for 3 bed house move",
    possibleOptions: [LIFTING_CHOICES_API[0], LIFTING_CHOICES_API[3]],
  },
];
