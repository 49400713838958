import Api from '../../../api/index';
import { SET_CARD_DETAILS_FROM_LOGIN } from '../VerifyMobile/VerifyMobileModalActions';

export const ATTEMPT_LOGIN_WITH_EMAIL_AND_PASSWORD = 'ATTEMPT_LOGIN_WITH_EMAIL_AND_PASSWORD';
export const LOGIN_WITH_EMAIL_AND_PASSWORD_SUCCESSFUL = 'LOGIN_WITH_EMAIL_AND_PASSWORD_SUCCESSFUL';
export const LOGIN_WITH_EMAIL_AND_PASSWORD_FAILED = 'LOGIN_WITH_EMAIL_AND_PASSWORD_FAILED';

export const SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN = 'SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN';

export function loginWithEmailAndPassword(email, password) {
  return dispatch => {

    dispatch({
      type: ATTEMPT_LOGIN_WITH_EMAIL_AND_PASSWORD
    });

    const params = {
      email: email,
      password: password
    };

    Api.post('w/login/web-email-login', params)
      .then(resp => {
        if (resp.err) {

          dispatch({
            type: LOGIN_WITH_EMAIL_AND_PASSWORD_FAILED,
            payload: {
              errorMessage: resp.err
            }
          });

          return;
        }

        dispatch({
          type: LOGIN_WITH_EMAIL_AND_PASSWORD_SUCCESSFUL
        });

        dispatch({
          type: SET_PERSONAL_DETAILS_FROM_EMAIL_PASSWORD_LOGIN,
          payload: {
            firstName: resp.firstName,
            lastName: resp.lastName,
            email: resp.email,
            phone: resp.phone,
            phoneValidated: resp.phoneValidated,
            mp_distinct_id: resp.mp_distinct_id,
            hasWebLogin: true
          }
        });

        if (resp.card) {
          dispatch({
            type: SET_CARD_DETAILS_FROM_LOGIN,
            payload: {
              card: resp.card
            }
          })
        }
      })
  }
}
